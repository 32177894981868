<template>
  <div>
    <el-dialog
      title="生成内容"
      :close-on-click-modal="false"
      :visible.sync="DialogAddVisible"
      width="40%"
      v-dialogDrag
    >
      <div class="ctn">
        <!-- 标题 -->
        <div class="ctn-title">
          <div class="ctn-title-box">书写顺序</div>
          <div class="ctn-title-box">
            <div
              style="
                padding: 10px 15px;
                border: 1px solid #ddd;
                background: white;
              "
            >
              <el-checkbox v-model="autoWriteModel"
                ><p style="font-size: 18px">自动识别</p></el-checkbox
              >
            </div>
          </div>
          <div class="ctn-title-box" v-if="!autoWriteModel">
            <div
              style="
                padding: 10px 15px;
                border: 1px solid #ddd;
                display: flex;
                background: white;
              "
            >
              <el-radio v-model="landscape" label="1" style="display: flex"
                ><p style="font-size: 18px">横排</p></el-radio
              >
              <el-radio
                v-model="landscape"
                label="2"
                style="display: flex; margin-left: 8px"
                ><p style="font-size: 18px">竖排</p></el-radio
              >
            </div>
          </div>
          <div class="ctn-title-box">
            <div
              style="
                padding: 10px 15px;
                border: 1px solid #ddd;
                background: white;
              "
            >
              <el-checkbox v-model="holdSpace"
                ><p style="font-size: 18px">保留空格</p></el-checkbox
              >
            </div>
          </div>
        </div>
        <!-- 内容 -->
        <div
          class="paragraph"
          contenteditable="plaintext-only"
          @input="changeVal($event)"
          ref="paragraph"
          :class="[landscape == '2' ? 'paragraph1' : 'paragraph']"
        >
          {{ textgraph }}
        </div>
        <!-- 底部按钮 -->
        <div class="paragraph-bottom">
          <el-button
            type="primary"
            icon="el-icon-document"
            @click="Selectedgraph"
            >选中生成</el-button
          >
          <el-button
            type="primary"
            style="margin-left: 10px"
            icon="el-icon-document"
            @click="allgraph"
            >全部生成</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["graphVisible", "singlefrom"],
  data() {
    return {
      icon: "",
      //   自动识别
      autoWriteModel: false,
      //段落横排竖排
      landscape: "1",
      //保留空格
      holdSpace: false,
      input: "",
      textgraph: "",
    };
  },
  computed: {
    DialogAddVisible: {
      get() {
        return this.graphVisible;
      },
      set(val) {
        console.log("走了set");
        this.$emit("closer", val);
      },
    },
    Singledetail: {
      get() {
        return this.singlefrom;
      },
      set(val) {
        console.log("走了set");
        this.$emit("updatemsg", val);
      },
    },
  },
  watch: {
    landscape(val) {
      console.log(val);
    },
  },
  mounted() {
    // console.log(this.text);
  },
  inject: ["getChapterList"],
  methods: {
    changeVal(e) {
      // this.textgraph = e.target.innerHTML;
      // console.log(e.target.innerHTML);
    },
    // 全部生成
    allgraph() {
      this.landscape = 2;
      console.log(this.Singledetail);
      this.$request({
        url: "/api/proofread/user-center/build-paragraph-content",
        method: "POST",
        data: {
          sourceID: this.Singledetail.sourceID,
          ocrInfoID: this.Singledetail.id,
          fdCharDtos: [],//this.Singledetail.chars,
          holdSpace: this.holdSpace,
          autoWriteModel: this.autoWriteModel,
          landscape: this.landscape == 1 ? true : false,
        },
      })
        .then((res) => {
          console.log("res", res);
          this.$refs.paragraph.innerHTML = res.content;
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //选中生成
    Selectedgraph() {
      this.$refs.paragraph.innerHTML = this.getChapterList.codefontFontList
        .toString()
        .replace(/,/g, "");
    },
  },
};
</script>

<style lang="less" scoped>
.ctn {
  width: 100%;
  height: 550px;
  background: lightblue;
}
.ctn-title {
  width: 100%;
  height: 60px;
  background: #f5f5f5;
  display: flex;
}
.ctn-title-box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  flex: 1;
}
.paragraph {
  padding: 10px;
  width: 100%;
  height: 420px;
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 22px;
  overflow: scroll;
  font-family: 宋体;
}
.paragraph1 {
  padding: 10px;
  width: 100%;
  height: 420px;
  background: white;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 22px;
  writing-mode: vertical-rl;
  font-family: 宋体;
}
.paragraph:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%),
    0 0 8px rgb(102 175 233 / 60%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
}

.paragraph-bottom {
  padding-right: 10px;
  width: 100%;
  height: 70px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: right;
}

/deep/.el-radio {
  margin-right: 0;
}
// /deep/ .el-input__inner {
//   height: 420px !important;
// }
</style>