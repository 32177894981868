<template>
  <div class="c-right">
    <el-tabs v-model="lineageName" type="card" @tab-click="handleClick">
      <!-- 信息 -->
      <el-tab-pane label="文献信息" name="first">
        <div class="c-body">
          <h3 class="title">文献信息</h3>
          <div class="c-form">
            <div class="form">
              <el-form
                style="display: flex; flex-wrap: wrap; padding-top: 40px"
                :hide-required-asterisk="true"
                :label-width="formLabelWidth"
                :model="form"
              >
                <el-form-item
                  label="文献类型"
                  prop="title"
                  :label-width="formLabelWidth"
                >
                  <el-select
                    v-model="documentListDto.documentType"
                    placeholder="请选择内容"
                    filterable
                    allow-create
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in $store.getters.getDocumentTypeOption"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  label="题名"
                  prop="title"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    style="width: 100%"
                    autocomplete="off"
                    placeholder="请输入内容"
                    v-model="documentListDto.title"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="责任者"
                  prop="contributor"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    style="width: 100%"
                    autocomplete="off"
                    placeholder="请输入内容"
                    v-model="documentListDto.contributor"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="出版地"
                  prop="placeOfPublication"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    style="width: 100%"
                    autocomplete="off"
                    placeholder="请输入内容"
                    v-model="documentListDto.placeOfPublication"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="出版者"
                  prop="publisher"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    style="width: 100%"
                    autocomplete="off"
                    placeholder="请输入内容"
                    v-model="documentListDto.publisher"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="出版时间"
                  prop="dateOfPublication"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    style="width: 100%"
                    autocomplete="off"
                    placeholder="请输入内容"
                    v-model="documentListDto.dateOfPublication"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  label="页码"
                  prop="extent"
                  :label-width="formLabelWidth"
                >
                  <el-input
                    style="width: 100%"
                    autocomplete="off"
                    placeholder="请输入内容"
                    v-model="documentListDto.extent"
                  >
                  </el-input>
                </el-form-item>
                <template v-if="isLoadMore">
                  <el-form-item
                    :label="v.val"
                    :prop="v.label"
                    v-for="(v, i) in tempField"
                    :key="i"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="v[v.label]"
                    />
                  </el-form-item>
                  <el-form-item
                    label="其他题名"
                    prop="alternative"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.alternative"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="责任方式"
                    prop="role"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.role"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="中图分类"
                    prop="classification"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.classification"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="主题"
                    prop="keyword"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.keyword"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="附注"
                    prop="notes"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.notes"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="内容形式"
                    prop="contentForm"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.contentForm"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="媒体类型"
                    prop="mediaType"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.mediaType"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="格式"
                    prop="format"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.format"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="语种"
                    prop="language"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.language"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="关联"
                    prop="relation"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.relation"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="时间范围"
                    prop="temporal"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.temporal"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="空间范围"
                    prop="spatial"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.spatial"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="版本"
                    prop="edition"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.edition"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="适用对象"
                    prop="audience"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.audience"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="ISBN"
                    prop="isbn"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.isbn"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="统一书号"
                    prop="unionBooksNumber"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.unionBooksNumber"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="CDOI"
                    prop="cdoi"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.cdoi"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="发布地址"
                    prop="uri"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.uri"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="数据提交单位"
                    prop="owner"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.owner"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item
                    label="所属任务年份"
                    prop="recordDate"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      style="width: 100%"
                      autocomplete="off"
                      placeholder="请输入内容"
                      v-model="documentListDto.recordDate"
                    >
                    </el-input>
                  </el-form-item>
                </template>
              </el-form>
            </div>
          </div>
          <div class="edit-btn">
            <button v-if="!isLoadMore" @click="isLoadMore = !isLoadMore">
              查看更多字段
            </button>
            <button v-else @click="isLoadMore = !isLoadMore">
              关闭更多字段
            </button>
            <button @click="editmsg" style="margin: 0 10px">
              保存文献信息
            </button>
            <!-- <button @click="addField">添加字段</button> -->
          </div>
        </div>
      </el-tab-pane>
      <!-- 缩略图 -->
      <el-tab-pane label="缩略图" name="second">
        <div class="pic">
          <el-tag type="info">全文检索</el-tag>
          <el-select
            size="mini"
            @change="typebtn"
            v-model="typevalue"
            placeholder="请选择"
            style="display: none"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-select
            size="mini"
            @change="Imgbtn"
            style="width: 100px; display: none"
            v-model="imgvalueoptionsValue"
            placeholder="请选择"
          >
            <el-option
              v-for="item in imgvalueoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-input
            size="mini"
            v-model="imgvalue"
            style="width: 100px"
            placeholder="请输入内容"
          ></el-input>
          <el-button type="success" size="mini" @click="btnSearch"
            >查询</el-button
          >
          <el-tag type="info" v-show="isShowSearchCount"
            >匹配查询{{ Totals }}条记录</el-tag
          >
        </div>
        <div class="pic-ctn">
          <!-- 缩略图列表 -->
          <div class="pic-list">
            <div
              style="
                padding: 10px;
                padding-bottom: 40px;
                display: flex;
                flex-wrap: wrap;
              "
            >
              <div
                class="pic-list-box"
                v-for="(item, index) in ImgDetailList"
                :key="item.id"
                @click="urldetail(item.id, index)"
                :class="{
                  picListImgbox: thumbnailIndex == index,
                }"
              >
                <el-image
                  fit="contain"
                  style="width: 100%; height: 85%"
                  :src="`${baseurl}api/fileinfo/image/GetOriImageThumbnail?filename=${
                    item.pictureFullName
                  }&ocrSource=${ocrSource == 1 ? 1 : 2}`"
                ></el-image>
                <div class="pic-list-box-button">
                  {{ item.pictureFileName }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pic-ctn-bottom">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="PageIndex"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="PageSize"
            prev-text="上一页"
            next-text="下一页"
            background
            layout="total, prev, pager, next"
            :total="Totals"
          >
          </el-pagination>
        </div>
      </el-tab-pane>
      <!-- 段落 -->
      <el-tab-pane label="段落" name="third">
        <div class="pic">
          <el-button size="small" style="margin-left: 20px">书写顺序</el-button>
          <div style="margin-left: 10px">
            <el-radio v-model="rdlr" size="small" label="2" border @change="rdl"
              >从左到右</el-radio
            >
            <el-radio v-model="rdlr" size="small" label="1" border @change="rdr"
              >从右到左</el-radio
            >
          </div>
        </div>
        <div
          @input="plaintextbtn"
          id="articleDetails"
          contenteditable="plaintext-only"
          :class="[rd == 2 ? 'paragraph1' : 'paragraph']"
          v-html="singlefrom.content"
        ></div>
        <div style="width: 100%; display: flex; justify-content: right">
          <!--<el-button type="primary" size="small">批量生成段落</el-button>-->
          <el-button
            @click="DownContent"
            type="primary"
            size="small"
            icon="el-icon-download"
            style="margin-right: 10px"
            >二维码获取</el-button
          >
          <el-button
            @click="quote"
            type="primary"
            size="small"
            icon="el-icon-download"
            >引用生成获取</el-button
          >
          <el-button
            @click="Theparagraph"
            type="primary"
            size="small"
            style="margin-left: 10px"
            >生成段落</el-button
          >
          <el-button
            type="success"
            size="small"
            style="margin-left: 10px"
            @click="SaveTheparagraph"
            >保存</el-button
          >
        </div>
      </el-tab-pane>
    </el-tabs>
    <!-- 生成段落 -->
    <el-dialog
      top="30vh"
      title="提示"
      :close-on-click-modal="false"
      :visible.sync="paragraph"
      width="300px"
    >
      <span>确定要重新生成段落内容吗?</span><br />
      <div style="margin-top: 10px">
        <span>书写顺序</span>
        <el-checkbox style="margin-left: 10px" v-model="autoWriteModel"
          >自动识别</el-checkbox
        >
      </div>
      <br />
      <div v-if="!autoWriteModel">
        <el-radio v-model="landscape" label="1">横排</el-radio>
        <el-radio v-model="landscape" label="2">竖排</el-radio>
      </div>
      <br />
      <div>
        <span>保留空格</span>
        <el-checkbox style="margin-left: 10px" v-model="holdSpace"
          >是</el-checkbox
        >
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paragraph = false">取 消</el-button>
        <el-button
          style="margin-left: 10px"
          type="primary"
          @click="ConfirmParagraph"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!--获取识别结果-->
    <el-dialog
      top="30vh"
      title="扫一扫，获取识别结果"
      :close-on-click-modal="false"
      :visible.sync="showDownContent"
      width="600px"
    >
      <div class="image" style="text-align: center">
        <vue-qr :text="downContentUrl" :size="400" :logoScale="0.2"> </vue-qr>
      </div>
    </el-dialog>
    <add-field
      :is-show.sync="isAddFieldDialogShow"
      @updateForm="updateForm"
      :filed-form="filedForm"
      @minusOne="minusOne"
    />
    <!-- 引用生成弹窗 -->
    <el-dialog
      title="引用生成"
      :visible.sync="isQuoteDialogShow"
      :close-on-click-modal="false"
    >
      <el-dialog
        width="30%"
        title="更改序号"
        :visible.sync="innerVisible"
        append-to-body
      >
        <el-input v-model="serialNo" placeholder="请输入内容"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="innerVisible = false">取 消</el-button>
          <el-button type="primary" @click="confirmSerisNo">确 定</el-button>
        </span>
      </el-dialog>
      <span
        style="word-spacing: 20px"
        id="spanContentId"
        v-html="singlefrom.content"
      ></span>
      <div style="margin-top: 20px">
        <span style="font-size: 18px">{{ citation }}</span>
      </div>
      <button @click="innerVisible = true" type="info" style="margin-top: 5px">
        更改序号
      </button>
      <!-- <div
        class="info"
        style="display: flex; justify-content: space-between; flex-wrap: wrap"
      >
        <span style="width: 30%; margin-top: 20px"
          ><b>文献类型：</b
          >{{ documentListDto.documentType | formatDocumentType(that) }}</span
        >
        <span style="width: 30%; margin-top: 20px"
          ><b>题名：</b>{{ documentListDto.title }}</span
        >
        <span style="width: 30%; margin-top: 20px"
          ><b>创建者：</b>{{ documentListDto.contributor }}</span
        >
        <span style="width: 30%; margin-top: 20px"
          ><b>主题：</b>{{ documentListDto.keyword }}</span
        >
        <span style="width: 30%; margin-top: 20px"
          ><b>出版社：</b>{{ documentListDto.publisher }}</span
        >
        <span style="width: 30%; margin-top: 20px"
          ><b>出版日期：</b>{{ documentListDto.dateOfPublication }}</span
        >
        <span style="width: 30%; margin-top: 20px"
          ><b>资源来源：</b>{{ documentListDto.owner }}</span
        >
      </div> -->
    </el-dialog>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import AddField from "@/components/AddField";
import FieldForm from "../fieldForm.vue";
export default {
  props: [
    "singlefrom",
    "sourceID",
    //"activeName",
    "documentListDto",
    "FineParticle",
    "docId",
  ],
  components: {
    AddField,
    VueQr,
    FieldForm,
  },

  data() {
    return {
      isShowSearchCount: false,
      bookDto: {
        documentTypeText: "", // 字符串文件类型
        title: "", // 题名
        sourceID: "", // 加工资源ID
        owner: "", // 数据提交单位
        documentType: 134, //文献类型
        contributor: "", //责任者
        keyword: "", //主题
        abstract: "", //描述（摘要）
        publisher: "", //出版者名称
        dateOfPublication: "", //出版日期
        classification: "口", //中图分类
        contentForm: "口", //内容形式
        mediaType: "口", //媒体类型
        format: "口", //格式
        language: "chi", //语种
        audience: "口", //适用对象
        recordDate: "口", // 所属任务年份
        placeOfPublication: "", // 出版地
        extent: "", // 页码
        alternative: "", // 其他题名
        role: "", // 责任方式
        notes: "", // 附注
        relation: "", // 关联
        temporal: "", // 时间范围
        spatial: "", // 空间范围
        edition: "", // 版本
        isbn: "", // isbn
        unionBooksNumber: "", // 统一书号
        cdoi: "", // cdoi
        uri: "", // 发布地址
      },
      innerVisible: false,
      serialNo: "序号",
      citation: "",
      tempField: [],
      formLabelWidth: "120px",
      that: this,
      isLoadMore: false,
      coordinate: null, // 按条件查找得到的坐标信息
      frequency: 0,
      filedForm: {
        fieldEnglishName: "",
        fieldChinaName: "",
        fieldValue: "",
      },
      isQuoteDialogShow: false,
      isAddFieldDialogShow: false,
      addFieldObj: [],
      downContentUrl: "", //扫一扫获取识别结果地址
      updateradio: 2,
      ocrSource: 1,
      /* 分页 */
      PageIndex: 1,
      PageSize: 10,
      Totals: 0,
      detailId: "",
      loading: false,
      chapterData: [], //章节列表
      activeName: "first",
      editName: "first",
      form: {
        name: "",
      },
      //世系成员编辑传的值
      selectId: "",
      selectFullName: "",
      // 单页校对表单信息
      show: "",
      radio: "1",
      radio1: "",
      //段落-从左往右-从右往左
      rdlr: "1",
      rd: "",
      checked: "",
      //段落-自动识别
      autoWriteModel: false,
      //段落-保留空格
      holdSpace: false,
      //段落-横排竖排
      landscape: "2",
      //缩略图下标
      thumbnailIndex: -1,
      // 编辑组件
      genealogyCurren: true,
      showDownContent: false,
      options: [
        {
          value: "1",
          label: "原图",
        },
        // {
        //   value: "2",
        //   label: "修后图",
        // },
      ],
      typevalue: "1",
      flag: false,
      chapterinput: "",
      dialogVisible: false,
      ImgVisible: false,
      n: 90,
      m: 0,
      imgvalue: "",
      imgvalueoptions: [
        {
          value: "1",
          label: "文件名",
        },
        // {
        //   value: "2",
        //   label: "人物姓名",
        // },
        {
          value: "3",
          label: "文字内容",
        },
      ],
      imgvalueoptionsValue: "3",
      ImageType: 3,
      threelist: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      keylist: [],
      threeid: "",
      value2: 50,
      value3: 100,
      dfocus: false,
      dfocus1: false,
      // 当前详情页id
      imgid: "",
      //段落文字内容
      // content: "",
      //期刊类型100 图书,110 期刊,120 报纸,131 方志、132 历史档案、133 谱牒、134 古籍，150 音频，160 视频
      documentType: "",
      TypeName: "",
      //信息块通用字段
      GeneralField: null,
      baseurl: this.baseURL,
      ImgDetailList: [],
      //编辑族谱信息弹出层
      addDialogFormVisible: false,
      addGenealogyForm: {},
      //族谱编辑
      addGenealogyForm1: {
        title: "123",
        alternative: "123",
        version: "",
        edition: "",
        barCode: "",
        surname: "",
        surnamePinYin: "",
        area: "",
        pages: "",
        copies: "",
        dTime: "",
        hall: "",
        diaspora: "",
        layoutFormat: "",
        contributor: "",
        role: "",
        classification: "",
        keyword: "",
        abstract: "的分公司的",
        publisher: "",
        placeOfPublication: "",
        dateOfPublication: "",
        contentForm: "",
        mediaType: "",
        format: "",
        extent: "",
        language: "",
        relation: "",
        temporal: "",
        spatial: "",
        audience: "",
        isbn: "",
        unionBooksNumber: "",
        cdoi: "",
        uri: "",
        recordDate: "",
        extendFieldDtos: [],
        notes: "",
        ancestor: "",
      },
      queryid: -1,
      // 段落生成弹出层
      paragraph: false,
      //章节目次弹窗图片列表
      ocrInfoItems: [],
      isopen: false,
      isopenid: "",
      editbatchid: "",
      isedit: "",
      //章节目次图片添加移除
      identifier: "",
      ocrInfoItemsData: [],
      //章节目次图片列表
      ocrInfoImgItems: [],
      //树形结构当前项下标
      threeListindex: "",
      plaintext: "",
      clearname: "",
      nameParticleVisible: false,
      //世系成员当前项信息
      membersmsg: "",
      parentId: "",
      ResultsList: [],
      TableList: [],
      formLabelWidth: "124px",
    };
  },
  mounted() {
    setTimeout(() => {
      // .substring(0,5);
      this.Imglist();
      // this.confirmtableList();
      this.plaintext = this.singlefrom.content;
      this.downContentUrl =
        "http://" +
        window.location.host +
        "/#/downContent?token=" +
        this.$cookies.get("TOKEN") +
        "&id=" +
        this.singlefrom.id +
        "&sourceID=" +
        this.singlefrom.sourceID;
    }, 3000);

    window.onload = () => {
      let answer = document.getElementById("articleDetails");
      answer.addEventListener("copy", (e) => {
        this.setClipboardText(e);
      });
    };
  },
  filters: {
    formatDocumentType(documentType, that) {
      if (!documentType) return;
      if (typeof documentType === "number") {
        return that.$store.state.DocumentTypeOption.filter(
          (v) => v.value === documentType
        )[0].label;
      }
      return documentType;
    },
  },
  beforeDestroy() {
    window.removeEventListener("copy", this.setClipboardText());
  },
  watch: {
    sourceID(val) {
      console.log(Imglistid + "id");
    },
    singlefrom(val) {
      this.documentType = val.documentListDto.documentType;
      // this.addGenealogyForm = Object.assign(this.addGenealogyForm1, val);
      // console.log(this.addGenealogyForm);
      // this.chapter();
    },
    "documentListDto.documentType": {
      handler(val) {
        this.setTempField(val);
      },
      immediate: true,
    },
  },
  computed: {
    lineageName: {
      get() {
        return this.activeName;
      },
      set(val) {
        this.$emit("updatemsg", val);
      },
    },
  },
  //章节期刊列表
  inject: ["getChapterList"],
  //打开章节目次编辑窗口
  provide() {
    return {
      EditgetChapter: this,
    };
  },
  methods: {
    confirmSerisNo() {
      this.setCitation();
      this.innerVisible = false;
    },
    setTempField(value) {
      switch (value) {
        // 古籍
        case 134:
          // this.tempField = JSON.parse(
          //   JSON.stringify(this.$store.state.ancientWorks)
          // );
          this.tempField = this.$store.state.ancientWorks;
          break;
        // 谱牒
        case 133:
          // this.tempField = JSON.parse(
          //   JSON.stringify(this.$store.state.genealogy)
          // );
          this.tempField = this.$store.state.genealogy;
          break;
        // 方志
        case 131:
          // this.tempField = JSON.parse(
          //   JSON.stringify(this.$store.state.localRecords)
          // );
          this.tempField = this.$store.state.localRecords;
          break;
        // 历史档案
        case 132:
          // this.tempField = JSON.parse(
          //   JSON.stringify(this.$store.state.historicalArchives)
          // );
          this.tempField = this.$store.state.historicalArchives;
          break;
        // 图书
        case 100:
          this.tempField = [];
          break;
      }
    },
    setCitation() {
      switch (this.documentListDto.documentType) {
        // 古籍
        case 134:
          this.citation = `[${this.serialNo}]${this.documentListDto.contributor}.${this.documentListDto.title}[M]${this.documentListDto.placeOfPublication}.${this.documentListDto.publisher}.${this.documentListDto.dateOfPublication}:${this.documentListDto.extent};`;
          break;
        // 谱牒
        case 133:
          this.citation = `[${this.serialNo}]${this.documentListDto.contributor}.${this.documentListDto.title}[M]${this.documentListDto.surname}.${this.documentListDto.hall}.${this.documentListDto.placeOfPublication}.${this.documentListDto.publisher}.${this.documentListDto.dateOfPublication}:${this.documentListDto.extent};`;
          break;
        // 方志
        case 131:
          this.citation = `[${this.serialNo}]${this.documentListDto.contributor}.${this.documentListDto.title}[M]${this.documentListDto.placeOfPublication}.${this.documentListDto.publisher}.${this.documentListDto.dateOfPublication}:${this.documentListDto.extent};`;
          break;
        // 历史档案
        case 132:
          this.citation = `[${this.serialNo}]${this.documentListDto.contributor}.${this.documentListDto.title}[M].${this.documentListDto.fileNumber}.${this.documentListDto.placeOfPublication}.${this.documentListDto.publisher}.${this.documentListDto.dateOfPublication}:${this.documentListDto.extent};`;
          break;
        // 图书
        case 100:
          this.citation = `[${this.serialNo}]${this.documentListDto.contributor}.${this.documentListDto.title}[M]${this.documentListDto.placeOfPublication}.${this.documentListDto.publisher}.${this.documentListDto.dateOfPublication}:${this.documentListDto.extent};`;
          break;
      }
    },

    // 复制携带版权信息
    setClipboardText(event) {
      event.preventDefault(); //阻止元素发生默认的行为
      const node = document.createElement("div");
      //对documentfragment不熟，不知道怎么获取里面的内容，用了一个比较笨的方式
      node.appendChild(window.getSelection().getRangeAt(0).cloneContents());
      //getRangeAt(0)返回对基于零的数字索引与传递参数匹配的选择对象中的范围的引用。对于连续选择，参数应为零。
      // 本文地址
      // let articleUrl = window.location.href;
      // 网站地址
      // let websiteUrl = window.location.origin;
      this.setCitation();
      let htmlData =
        "<div>" +
        node.innerHTML.replace(/\n/g, "") +
        `${this.citation}` +
        "</div>";
      let textData =
        // window.getSelection().getRangeAt(0).replaceAll("\n", "") +
        node.innerText.replace(/\n/g, "") +
        "\n" +
        "\n" +
        "---------------------------" +
        "\n" +
        "\n" +
        `${this.citation}` +
        "\n\n";
      if (event.clipboardData) {
        event.clipboardData.setData("text/html", htmlData);
        //setData(剪贴板格式, 数据) 给剪贴板赋予指定格式的数据。返回 true 表示操作成功。
        event.clipboardData.setData("text/plain", textData);
      } else if (window.clipboardData) {
        //window.clipboardData的作用是在页面上将需要的东西复制到剪贴板上，提供了对于预定义的剪贴板格式的访问，以便在编辑操作中使用。
        return window.clipboardData.setData("text", textData);
      }
    },
    // frequency自减一
    minusOne() {
      this.frequency--;
    },
    // 引用生成
    async quote() {
      this.isQuoteDialogShow = true;
      this.setCitation();
      // await this.$nextTick();
      // let dialog = document.getElementById("spanContentId");
      // dialog.addEventListener("copy", (e) => {
      //   this.setClipboardText(e);
      // });
    },
    deleteFiled(index) {
      this.addFieldObj.splice(index, 1);
    },
    updateForm(data) {
      this.addFieldObj.push({ ...data });
    },
    // 新增字段
    addField() {
      if (this.documentListDto.extendFieldDtos.length) {
        this.frequency = this.documentListDto.extendFieldDtos.length;
      }
      this.frequency += 1;
      this.filedForm.fieldEnglishName = "field" + this.frequency;
      this.isAddFieldDialogShow = true;
    },
    //获取识别结果
    DownContent() {
      this.showDownContent = true;
    },
    plaintextbtn(e) {
      console.log(e.target.innerHTML);
      this.plaintext = e.target.innerHTML;
    },
    //条数
    handleSizeChange(val) {
      this.PageSize = val;
      this.Imglist(this.singlefrom.sourceID);
    },
    //页数
    handleCurrentChange(val) {
      this.PageIndex = val;
      this.Imglist(this.singlefrom.sourceID);
    },
    clearId() {
      this.detailId = "";
    },
    updata(e) {
      console.log(e);
      this.ocrInfoItems = e.ocrInfoItems;
      // if(e){
      //   this.RefreshList()
      // }
    },

    Refresh() {},
    TheQuery() {
      this.getChapterList.getChapter(
        `/api/catalogue/${this.getChapterList.TypeName}`,
        this.sourceID
      );
    },
    InputfullName(e) {
      if (e == "") {
        console.log("清空了");
        this.getChapterList.wordfontList = [];
        this.getChapterList.FinegranularityLits = [];
        this.getChapterList.testfontList = [];
        this.getChapterList.codefontList = [];
        this.getChapterList.codefontFontList = [];
        this.getChapterList.MemberWordModelDto = [];
      }
    },
    QueryResults() {
      this.$request({
        url: `/api/catalogue/${this.getChapterList.TypeName}`,
        method: "GET",
        params: {
          IsNoPage: true,
          PageSize: 20,
          PageIndex: 1,
          SourceID: this.sourceID,
          ParentId: "00000000-0000-0000-0000-000000000000",
          IsSearchRoot: true,
          Keyword: this.chapterinput,
          TaskNo: this.getChapterList.taskNo,
        },
      })
        .then((res) => {
          this.ResultsList = res.items;
          // console.log(res.items);

          for (let i of res.items) {
            if (i.title == this.chapterinput) {
              this.editName = "first";
            }
          }
        })
        .catch((e) => {
          //return this.$message.error("服务器异常~");
        });
    },
    //刷新
    RefreshList() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 500);
      console.log("刷新");
      console.log(this.getChapterList);
      this.getChapterList.getChapter(
        `/api/catalogue/${this.getChapterList.TypeName}`,
        this.getChapterList.sourceID
      );
    },
    OcrSource(e) {
      console.log(e);
      this.typevalue = e;
    },
    btnSearch() {
      this.isShowSearchCount = true;
      this.Imglist();
    },
    // 缩略图列表详情
    Imglist() {
      if (this.typevalue == 1) {
        this.ocrSource = 1;
      } else {
        this.ocrSource = 2;
      }
      this.$request({
        url: "/api/proofread/user-center/document-ocr-dtos",
        method: "GET",
        headers: {
          accept: "text/plain",
        },
        params: {
          PageSize: this.PageSize,
          PageIndex: this.PageIndex,
          SourceID: this.sourceID,
          SkipCount: "",
          MaxResultCount: "",
          Sorting: "",
          OcrSource: this.typevalue,
          SearchType: this.ImageType,
          SearchValue: this.imgvalue,
        },
      })
        .then((res) => {
          console.log("ImgDetailList", res);

          this.ImgDetailList = res.items;

          this.Totals = res.totalCount;
          this.$emit("setTotals", this.Totals);
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    editNopeople(item) {
      this.selectId = item.id;
      this.selectFullName = item.fullName;
      this.$refs.editAncestor.GetDetail(item.id);
    },
    async urldetail(urlid, index) {
      this.thumbnailIndex = index;
      this.$emit("changeurl", urlid, index);
      // 更改当前地址参数
      let query = this.$router.history.current.query;
      let path = this.$router.history.current.path;
      console.log(query, path);
      await this.$router.push({
        path: "/proofreading/singlePageDetail",
        query: {
          id: this.singlefrom.id,
          sourceID: this.singlefrom.sourceID,
          index: 0,
          ocrSource: this.singlefrom.ocrSource,
        },
      });
      if (this.imgvalue !== "") {
        try {
          const res = await this.$request({
            method: "GET",
            url: `/api/proofread/user-center/${urlid}/document-oCRInfo`,
            params: {
              keyWord: this.imgvalue,
            },
          });
          this.coordinate = res.wordRcImages;
          this.$emit("coordinate", this.coordinate);
        } catch (err) {
          console.log(err);
        }
      }
    },
    handleClick(e) {
      console.log;
    },
    // 生成段落
    Theparagraph() {
      this.paragraph = true;
    },
    //确认生成段落
    ConfirmParagraph() {
      console.log(this.landscape);
      this.$request({
        url: "/api/proofread/user-center/build-paragraph-content",
        method: "POST",
        data: {
          sourceID: this.singlefrom.sourceID,
          ocrInfoID: this.singlefrom.id,
          fdCharDtos: [], // this.singlefrom.chars,
          holdSpace: this.holdSpace,
          autoWriteModel: this.autoWriteModel,
          landscape: this.landscape == 1 ? true : false,
        },
      })
        .then((res) => {
          this.singlefrom.content = res.content;
          this.plaintext = res.content;
          this.paragraph = false;
          document.getElementById("articleDetails").innerHTML = res.content;
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //保存段落
    SaveTheparagraph() {
      this.$request({
        url: "/api/proofread/user-center/save-page-content",
        method: "POST",
        data: {
          genealogyOCRInfoID: this.singlefrom.id,
          content: this.plaintext,
        },
      })
        .then((res) => {
          this.getChapterList.singledetail(this.getChapterList.taskNo);
          return this.$message.success("保存成功~");
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    // 从左往右
    rdl(e) {
      // this.singlefrom.content = this.singlefrom.content
      //   .replaceAll("︵", "(")
      //   .replaceAll("︶", ")")
      //   .replaceAll("︽", "《")
      //   .replaceAll("︾", "》")
      //   .replaceAll("﹃", "『")
      //   .replaceAll("﹄", "』");
      this.singlefrom.content = this.singlefrom.content
        .replace(/︵/g, "(")
        .replace(/︶/g, ")")
        .replace(/︽/g, "《")
        .replace(/︾/g, "》")
        .replace(/﹃/g, "『")
        .replace(/﹄/g, "』");
      this.rd = e;
    },
    //从右到左
    rdr(e) {
      this.singlefrom.content = this.singlefrom.content
        .replace(/\(/g, "︵")
        .replace(/\)/g, "︶")
        .replace(/《/g, "︽")
        .replace(/》/g, "︾")
        .replace(/『/g, "﹃")
        .replace(/』/g, "﹄");

      this.rd = e;
    },
    popdetailadd() {
      console.log("hhhh");
      this.dialogVisible = true;
    },
    closer(val) {
      console.log(val);
      this.dialogVisible = false;
      this.ImgVisible = false;
      this.nameParticleVisible = val;
    },
    threeclick(e) {
      console.log(e);
      console.log("查看图片");
      this.ocrInfoItems = e;
      this.ImgVisible = true;
    },
    //右击时赋值操作
    rightbutton(val, e) {
      this.identifier = e.identifier;
      console.log(e);
      this.membersmsg = e;
      this.threeid = e.id;
      // this.editbatchid=e.id
      console.log(this.threeid);
      //打开图片
      this.ocrInfoItems = e.ocrInfoItems;
      //打开章节目前编辑
      this.isopenid = e.id;
      console.log(this.isopenid);
      console.log("右击了");
      //打开下拉窗口
      this.$nextTick(function () {
        this.$refs.messageDrop.show();
      });
    },
    Texthtml(e) {
      console.log(e, "填充备注");
      this.$refs.msg.fillbatch(this.isopenid, e[0]);
    },
    //下拉框对应的操作
    handleCommand(command) {
      //打开查看图片
      if (command == "a") {
        this.ImgVisible = true;
      }
      //打开章节编辑按钮
      if (command == "b") {
        this.isopen = true;
        this.isedit = "编辑";
        console.log(this.$refs.msg);
        this.$refs.msg.editbatch(this.isopenid);
        this.editbatch(this.isopenid);
      }
      if (command == "c") {
        console.log("章节删除");
        this.deltetbatch(this.isopenid);
        this.deltetTablebatch(this.isopenid);
      }
      if (command == "d") {
        console.log("填充备注");
        this.nameParticleVisible = true;
      }
      if (command == "e") {
        console.log("同步图表信息");
      }
    },
    // 关闭族谱编辑弹窗
    handleClose() {
      this.addDialogFormVisible = false;
    },
    Imgbtn(e) {
      console.log(e, "xx");
      this.ImageType = e;
    },
    typebtn(e) {
      this.typevalue = e;
    },
    // 找相同的字段
    getIdentical(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) !== arr.lastIndexOf(v);
      });
    },
    // 保存文献信息
    editmsg(id) {
      //this.addDialogFormVisible = true;
      // "f438509c-3f54-e703-4a90-3a01554369a5"
      // this.detailId = this.getChapterList.documentListDto.id;
      // this.$refs.gen.GetGenealogyDetail(this.getChapterList.documentListDto.id);

      this.documentListDto.documentTypeText = this.documentListDto.documentType;
      if (typeof this.documentListDto.documentTypeText === "number") {
        this.$store.state.DocumentTypeOption.forEach((v) => {
          if (v.value === this.documentListDto.documentTypeText) {
            this.documentListDto.documentTypeText = v.label;
          }
        });
      } else if (typeof this.documentListDto.documentTypeText === "string") {
        this.documentListDto.documentType = 0;
      }
      // 特有字段
      const tempList = {};
      this.tempField.forEach((v) => {
        tempList[v.label] = v[v.label];
      });
      // 公共字段
      const publicList = [
        ...new Set(
          this.getIdentical(
            Object.keys(this.documentListDto),
            Object.keys(this.bookDto)
          )
        ),
      ];
      // 请求参数
      const tempObj = {};
      publicList.forEach((v) => {
        tempObj[v] = this.documentListDto[v];
      });
      let payload = {};
      payload.documentType = tempObj.documentType;
      delete this.documentListDto.documentType;
      switch (payload.documentType) {
        // 古籍
        case 134:
          payload.ancientDto = tempObj;
          break;
        // 谱牒
        case 133:
          payload.genealogyDto = tempObj;
          break;
        // 方志
        case 131:
          payload.localRecordsDto = tempObj;
          break;
        // 历史档案
        case 132:
          payload.historicalArchivesDto = tempObj;
          break;
        // 图书
        case 100:
          payload.bookDto = tempObj;
          break;
      }
      Object.assign(tempObj, tempList);
      console.log(payload, "payload");
      this.$request({
        url: "/api/document/user-center/" + this.docId + "/update-book",
        method: "POST",
        data: {
          ...payload,
        },
      })
        .then(async (res) => {
          await this.$emit("reload");
          return this.$message.success("保存成功");
        })
        .finally((e) => {
          //this.loading = false;
        });
    },
    //点击查询列表切换颜色
    querybtn(index, item) {
      this.queryid = index;
      console.log(item);
    },
    //右击弹窗
    rightbtn(e) {
      for (let i of this.$refs.rightbox) {
        i.style.left = e.offsetX + "px";
        i.style.top = e.offsetY + "px";
        i.style.display = "block";
      }
      console.log(e.offsetX, e.offsetY);
    },
    //取消右击弹窗
    btnmember5(e) {
      for (let i of this.$refs.rightbox) {
        i.style.left = e.offsetX + "px";
        i.style.top = e.offsetY + "px";
        i.style.display = "none";
      }
    },
    //右击弹窗删除图片
    deltetimgbatch(item, e) {
      console.log(item);
      for (let i of this.$refs.rightbox) {
        i.style.left = e.offsetX + "px";
        i.style.top = e.offsetY + "px";
        i.style.display = "none";
      }
      this.$request({
        url: `/api/catalogue/${this.getChapterList.TypeName}/ocr-infos`,
        method: "PUT",
        data: {
          opType: 2,
          identifier: this.identifier,
          ocrInfoItems: [item],
        },
      })
        .then((res) => {
          this.RefreshList();
          // this.ocrInfoImgItems=this.getChapterList.getChapterList[this.threeListindex].ocrInfoItems
          console.log(this.ocrInfoImgItems);
          // return this.$message.success("保存成功");
        })
        .catch((e) => {});
    },
    getCurrentKey(e) {
      console.log(e);
    },
    //单击章节目次列表
    threeclickbtn(e, node, ele, index) {
      // console.log(node);
      // console.log(this.getChapterList.getChapterList[2].ocrInfoItems);
      let a = this.getChapterList.getChapterList.indexOf(e);
      console.log(e.id);
      this.parentId = e.id;
      this.FormLabelAlign.catalogueCode = "200";
      this.FormLabelAlign.parentId = e.id;
      this.FormLabelAlign.orderby = 1;
      this.FormLabelAlign.type = "正篇文章";
      this.FormLabelAlign.language = "chi";
      this.FormLabelAlign.extent = "1";
      this.FormLabelAlign.classification =
        this.getChapterList.singlefrom.documentListDto.classification;
      this.FormLabelAlign.startFileName = "口";
      this.FormLabelAlign.endFileName = "1";
      this.FormLabelAlign.filePath = this.getChapterList.singlefrom.picturePath;
      this.FormLabelAlign.keyword = "1";
      this.FormLabelAlign.pageNumber = "1";
      // console.log(a);
      this.threeListindex = a;
      this.ocrInfoItemsData = [];

      this.ocrInfoImgItems = e.ocrInfoItems;
      // this.ocrInfoImgItems = node.parent.data[this.threeListindex].ocrInfoItems;
      // console.log(this.getChapterList);
      this.identifier = e.identifier;
      this.ocrInfoItemsData.push({
        id: e.id,
        documentOCRInfoId: this.singlefrom.id,
        picturePath: this.singlefrom.picturePath,
        pictureFileName: this.singlefrom.pictureFileName,
        orderby: 1,
      });
      this.threeExpand(e);
      // console.log(this.ocrInfoItemsData);
    },
    //去重且添加图表
    threeExpand(data) {
      console.log(data);
      this.confirmtableList(data.id);
      setTimeout(() => {
        data.childList.push(...this.TableList);
        console.log(data.childList);
        // data.childList = [...new Set(data.childList.id)];
        const removeDuplicateObj = (arr) => {
          let obj = {};
          arr = arr.reduce((newArr, next) => {
            obj[next.id] ? "" : (obj[next.id] = true && newArr.push(next));
            return newArr;
          }, []);
          return arr;
        };
        data.childList = removeDuplicateObj(data.childList);
        console.log(removeDuplicateObj(data.childList));
      }, 200);
    },
    nodeExpand(e) {
      this.threeExpand(e);
    },
    Clearinquire(e) {
      // console.log(e);
      this.clearname = e;
    },
    //添加章节
    confirm() {
      // --------------------------
      console.log(this.FormLabelAlign);
      this.FormLabelAlign.sourceID = this.sourceID;
      this.FormLabelAlign.taskNo = this.getChapterList.taskNo;
      this.FormLabelAlign.title = this.chapterinput;
      this.FormLabelAlign.type = "正篇文章";
      this.FormLabelAlign.ocrInfoItems[0].pictureFileName =
        this.getChapterList.singlefrom.pictureFileName;
      this.FormLabelAlign.ocrInfoItems[0].pictureFullName =
        this.getChapterList.singlefrom.pictureFullName;
      this.FormLabelAlign.ocrInfoItems[0].picturePath =
        this.getChapterList.singlefrom.picturePath;
      this.FormLabelAlign.ocrInfoItems[0].filePath =
        this.getChapterList.singlefrom.picturePath;
      this.FormLabelAlign.ocrInfoItems[0].documentOCRInfoId =
        this.getChapterList.singlefrom.id;
      // -----------------------------------
      this.$request({
        url: `/api/catalogue/${this.getChapterList.TypeName}`,
        method: "POST",
        data: this.FormLabelAlign,
      })
        .then((res) => {
          let boxrightfont = this.getChapterList.$refs.boxrightfont;
          for (let q of boxrightfont) {
            q.style.background = ``;
          }
          this.FormLabelAlign.parentId = "00000000-0000-0000-0000-000000000000";
          this.getChapterList.codefontList = [];
          this.getChapterList.codefontFontList = [];

          this.chapterinput = "";
          // this.FormLabelAlign = {};
          //刷新
          this.getChapterList.getChapter(
            `/api/catalogue/${this.getChapterList.TypeName}`,
            this.sourceID
          );

          return this.$message.success("保存成功");
        })
        .catch((e) => {});
    },
    //清除章节添加的id
    clearparentId() {
      console.log("空白处");
      this.FormLabelAlign.parentId = "00000000-0000-0000-0000-000000000000";
    },
    //添加图表
    confirmtable() {
      // -----------------------------------
      this.FormLabelAlign.taskNo = this.getChapterList.taskNo;
      this.FormLabelAlign.sourceID = this.sourceID;
      this.FormLabelAlign.catalogueCode = 210;
      this.FormLabelAlign.ocrInfoItems[0].pictureFileName =
        this.getChapterList.singlefrom.pictureFileName;
      this.FormLabelAlign.ocrInfoItems[0].pictureFullName =
        this.getChapterList.singlefrom.pictureFullName;
      this.FormLabelAlign.ocrInfoItems[0].picturePath =
        this.getChapterList.singlefrom.picturePath;
      this.FormLabelAlign.ocrInfoItems[0].documentOCRInfoId =
        this.getChapterList.singlefrom.id;
      // ------------------------------------------
      if (this.FormLabelAlign.title == "") {
        return this.$message.error("请在左边选择组合一个章节名称");
      } else {
        this.$request({
          url: `/api/catalogue/table`,
          method: "POST",
          data: this.FormLabelAlign,
        })
          .then((res) => {
            this.confirmtableList(this.FormLabelAlign.parentId);
            let boxrightfont = this.getChapterList.$refs.boxrightfont;
            for (let q of boxrightfont) {
              q.style.background = ``;
            }

            this.getChapterList.codefontList = [];
            this.getChapterList.codefontFontList = [];
            this.chapterinput = "";
            // this.FormLabelAlign = {};
            // //刷新
            // this.getChapterList.getChapter(
            //   `/api/catalogue/${this.getChapterList.TypeName}`,
            //   this.sourceID
            // );
            return this.$message.success("保存成功");
          })
          .catch((e) => {});
      }
    },
  },
};
</script>

<style lang="less" scoped>
.c-right {
  box-sizing: border-box;
  width: 28%;
  border: 1px solid #ddd;

  .ul-list {
    display: flex;
    list-style: none;
    color: #333;
    border-bottom: 1px solid #ddd;

    .l-item {
      padding: 10px 15px;
      font-size: 14px;
      color: #337ab7;
    }

    .c-active {
      color: #555;
      cursor: default;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px 4px 0 0;
    }
  }

  .c-body {
    .title {
      padding: 10px 15px;
      background-color: #f5f5f5;
      border-bottom: 1px solid #ddd;
      color: #333;
      border-bottom: 1px solid #ddd;
    }

    .c-form {
      height: 635px;
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
      .form {
        height: 630px;
        overflow: auto;
      }

      .filedspan {
        padding: 0 1px;
        //display: none;
        position: absolute;
        top: 0;
        right: -15px;
        z-index: 99999999;
        color: red;
        cursor: pointer;
        margin-top: 13px;
        //background: red;
        span {
          display: block;
          line-height: 20px;
        }
        &:hover {
          display: block;
        }
      }
    }

    .edit-btn {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      padding: 10px 15px;
      background-color: #f5f5f5;

      button {
        width: fit-content;
        color: #fff;
        background-color: #337ab7;
        border-color: #2e6da4;
        border-radius: 4px;
        cursor: pointer;
        padding: 6px 12px;
        border: none;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }

  .relationship {
    padding: 5px;
    font-size: 13px;
    display: flex;
    overflow: auto;
    height: 90px;
  }
}

.pic {
  width: 100%;

  // background: rgb(240, 239, 239);
  border: 1px solid rgb(240, 239, 239);
  display: flex;
  align-items: center;

  //.global {
  //  display: inline-block;
  //  //background: red;
  //  font-size: 14px;
  //}
}

.pic-ctn {
  width: 100%;
  height: 733px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.pic-ctn-bottom {
  display: flex;
  align-items: center;
  justify-content: center;

  bottom: 0;
  position: absolute;
  width: 100%;
  // height: 60px;
  background: white;
  border-top: 1px solid #e9e8e8;
}

.pic-list {
  width: 100%;
  // height: 900px;
}

.paragraph {
  font-family: 宋体;
  width: 468px;
  height: 647px;
  writing-mode: tb-rl;
  font-size: 20px;
  padding: 10px;

  // white-space: pre-wrap;
  overflow-x: scroll;
  overflow-y: scroll;
}

.paragraph1 {
  font-family: 宋体;
  width: 468px;
  height: 647px;
  writing-mode: lr-tb;
  font-size: 20px;
  padding: 10px;
  line-height: 33px;
  // white-space: pre-wrap;
  overflow-x: hidden;
  overflow-y: scroll;
}

.paragraph1:focus {
  transition: border linear 0.1s, box-shadow linear 0.2s;
  box-shadow: 0 0 10px #66afe9; //向外漫射程度1
  -moz-box-shadow: 0 0 10px #66afe9; //向外漫射程度2
  -webkit-box-shadow: 0 0 10px #66afe9; //向外漫射程度3
  border: 1px solid #66afe9;
  outline: 0;
  padding: 9px;
}

.paragraph:focus {
  transition: border linear 0.1s, box-shadow linear 0.2s;
  box-shadow: 0 0 10px #66afe9; //向外漫射程度1
  -moz-box-shadow: 0 0 10px #66afe9; //向外漫射程度2
  -webkit-box-shadow: 0 0 10px #66afe9; //向外漫射程度3
  border: 1px solid #66afe9;
  outline: 0;
  padding: 9px;
}

.pic-list-box {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  width: 180px;
  height: 180px;
  border: 2px solid #ddd;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.picListImgbox {
  cursor: pointer;
  margin-top: 10px;
  margin-left: 10px;
  width: 180px;
  height: 180px;
  border: 2px solid rgb(116, 235, 87);
  border-radius: 3px;
  position: relative;
}

.pic-list-box-button {
  font-size: 10px;
  position: absolute;
  bottom: 3px;
  left: 5px;
}

.edit-title {
  width: 100%;
  height: 45px;
  background-color: #f5f5f5;
  color: #333;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.edit-input {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.members {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
}

.members1 {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}

.members2 {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}

.members3 {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}

.members5 {
  width: 100%;
  // margin-left: 10px;
  height: 500px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.members5img {
  margin-left: 15px;
  position: relative;
  margin-top: 30px;
  float: left;
  width: 132px;
  height: 132px;
  // background: blanchedalmond;
}

.members5img-font {
  font-size: 6px;
  position: absolute;
  bottom: 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.members6 {
  width: 98%;
  // margin-left: 10px;
  height: 380px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.members5-list {
  width: 98%;
  margin-top: 10px;

  height: 300px;
  // border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.members5-list-box {
  margin-left: 10px;
  width: 150px;
  height: 150px;

  border: 1px solid #ddd;
  border-radius: 5px;
}

.mulu-detail {
  width: 100%;
  display: flex;
  justify-content: right;
  position: relative;
}

// 右击盒子
.right-btn-box {
  width: 120px;
  // height: 100px;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  box-shadow: 2px 2px 5px #666;
  border: 1px solid #aab4b4;
  padding: 1px;
}

.right-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  color: black;
  cursor: pointer;
}

.right-list:hover {
  background: #337ab7;
  z-index: 99;
}

/* ---------------------------------- */
.left-btn-box {
  width: 120px;
  height: 45px;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  box-shadow: 2px 2px 5px #666;
  border: 1px solid #aab4b4;
  padding: 1px;
}

// --------------------------------------
// 树形右击盒子
// 右击盒子
.right-btn-box1 {
  width: 120px;
  height: 100px;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // display: none;
  box-shadow: 2px 2px 5px #666;
  border: 1px solid #aab4b4;
  padding: 1px;
}

.right-list1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  color: black;
  cursor: pointer;
}

.right-list1:hover {
  background: #337ab7;
  z-index: 99;
}

.querybox {
  position: relative;
  border: 1px solid #ddd;
  width: 100%;
  height: 300px;
  font-size: 13px;
  // background: lightblue;
}

.querylist {
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 15px;
  background: rgb(240, 238, 238);
  border: 1px solid black;
  float: left;
  padding: 5px;
  overflow: auto;
  max-height: 320px;
  cursor: pointer;
}

.querylist1 {
  border-radius: 5px;
  margin-top: 15px;
  margin-left: 15px;
  background: rgb(88, 173, 102);
  border: 1px solid rgb(29, 29, 29);
  float: left;
  padding: 5px;
  overflow: auto;
  max-height: 320px;
  cursor: pointer;
  color: white;
}

.c-body {
  /deep/ .el-form-item__label {
    color: #333;
    font-weight: 700;
  }

  /deep/ .el-form-item__label,
  .el-input {
    height: 30px;
    line-height: 30px;
  }

  /deep/ .el-form-item__content {
    height: 30px;
    line-height: 30px;
  }
}

/deep/ .el-card__body {
  padding: 0;
}

/deep/.el-button + .el-button {
  margin-left: 0;
}

/deep/.but .el-button {
  border-radius: 0;
}

.boxx-right::-webkit-scrollbar {
  width: 0 !important;
}

.boxx-left::-webkit-scrollbar {
  width: 0 !important;
}

/deep/.el-tabs__header {
  margin: 0px;
}

/deep/.pic .el-radio {
  margin-right: 0px;
}

.allparticle {
  height: 704px;
  width: 100%;
  overflow: auto;
  padding-bottom: 50px;
}

.allparticle::-webkit-scrollbar {
  width: 0;
}

/deep/.el-collapse-item__header {
  margin-left: 15px !important;
}
</style>
