<template>
  <el-container>
    <el-main style="padding: 0">
      <div style="padding: 8px 15px">
        <el-card class="card" ref="card">
          <div class="header">
            <div style="display: flex">
              {{ singlefrom.pictureFileName }}
              <div style="margin-left: 10px">总数:{{ Totals }}</div>
            </div>
            <!-- <div class="btn">切到WT</div> -->
          </div>

          <div class="content">
            <div class="c-left">
              <div class="header-button">
                <p style="margin-left: 15px; font-size: 15px">对比模式:</p>
                <div style="margin-left: 5px">
                  <el-radio
                    v-model="radio"
                    label="1"
                    border
                    @change="radi"
                    size="mini"
                    >左右</el-radio
                  >
                </div>
                <div style="margin-left: 5px">
                  <el-radio
                    v-model="radio"
                    label="2"
                    border
                    @change="radi"
                    size="mini"
                    >双层</el-radio
                  >
                </div>
                <div style="width: 100px; margin-left: 10px" v-if="show == 2">
                  <el-checkbox v-model="hiddenback">隐藏背景</el-checkbox>
                  <el-checkbox v-model="hiddenproofread"
                    >隐藏校对层</el-checkbox
                  >
                </div>
                <div
                  v-if="show == 2"
                  style="
                    width: 190px;
                    margin-left: 5px;
                    display: flex;
                    align-items: center;
                    position: relative;
                  "
                >
                  <p style="font-size: 14px">左右:</p>
                  <!-- 滑块 -->
                  <div
                    v-if="dfocus"
                    style="
                      width: 200px;
                      height: 18px;
                      position: absolute;
                      left: 0;
                      z-index: 100;
                      bottom: -30px;
                      background: white;
                      line-height: 30px;
                      border-radius: 5px;
                      box-shadow: 1px 3px 5px black;
                    "
                  >
                    <el-slider
                      v-model="Xvalue"
                      style="margin-top: -10px"
                      :min="-200"
                      :max="200"
                    >
                    </el-slider>
                  </div>
                  <!-- 滑块2 -->
                  <div
                    v-if="dfocus1"
                    style="
                      width: 200px;
                      height: 18px;
                      position: absolute;
                      left: 0;
                      z-index: 100;
                      bottom: -30px;
                      background: white;
                      line-height: 30px;
                      border-radius: 5px;
                      box-shadow: 1px 3px 5px black;
                    "
                  >
                    <el-slider
                      v-model="Yvalue"
                      style="margin-top: -10px"
                      :min="-200"
                      :max="200"
                    >
                    </el-slider>
                  </div>
                  <el-input
                    @blur="blur"
                    @focus="focus"
                    v-model="Xvalue"
                    placeholder=""
                    style="width: 50px; margin-left: 5px"
                  ></el-input>
                  <p style="font-size: 14px; margin-left: 5px">上下:</p>
                  <el-input
                    @blur="blur1"
                    @focus="focus1"
                    v-model="Yvalue"
                    placeholder=""
                    style="width: 50px; margin-left: 5px"
                  ></el-input>
                </div>
                <div style="margin-left: 10px; display: flex">
                  <!-- <el-tooltip
                class="item"
                effect="dark"
                content="选择"
                placement="top"
              >
                <el-button size="small" icon="el-icon-position"></el-button>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="标引"
                placement="top"
              >
                <div
                  @click="editlineage"
                  :class="[isautofocus ? 'toopbtn1' : 'toopbtn']"
                >
                  <i class="el-icon-user-solid"></i>
                </div>
              </el-tooltip>-->
                  <!-- <el-tooltip
                class="item"
                effect="dark"
                content="选区"
                placement="top"
              >
                <el-button size="small" icon="el-icon-news"></el-button>
              </el-tooltip> -->
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="清除选区"
                    placement="top"
                  >
                    <el-button
                      size="small"
                      icon="el-icon-news"
                      @click="clearchoose"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="删除所选"
                    placement="top"
                  >
                    <el-button
                      size="small"
                      icon="el-icon-delete"
                      @click="cleararea"
                    ></el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="放大"
                    placement="top"
                  >
                    <el-button
                      size="small"
                      icon="el-icon-zoom-in"
                      @click="amplification(0.03)"
                    >
                    </el-button>
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="缩小"
                    placement="top"
                  >
                    <el-button
                      size="small"
                      icon="el-icon-zoom-out"
                      @click="narrow(0.03)"
                    >
                    </el-button>
                  </el-tooltip>
                  <!-- <el-tooltip
                class="item"
                effect="dark"
                content="切换成员树视图"
                placement="top"
              >
                <div
                  @click="switchmember"
                  :class="[MembersFamily ? 'toopbtn1' : 'toopbtn']"
                >
                  <i class="el-icon-s-grid"></i>
                </div>
              </el-tooltip> -->

                  <!-- <el-dropdown
                v-if="taskNo.substring(0, 2) == 'ZS' || taskNo == ''"
                @command="ParticleAccording"
              >
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="切换细颗粒视图"
                  placement="top"
                >
                  <div
                    @click="Particlemember"
                    :class="[memberParticleVisible ? 'toopbtn1' : 'toopbtn']"
                  >
                    <i class="el-icon-menu"></i>
                  </div>
                </el-tooltip>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="全部">全部</el-dropdown-item>
                  <el-dropdown-item command="人">人</el-dropdown-item>
                  <el-dropdown-item command="地">地</el-dropdown-item>
                  <el-dropdown-item command="事">事</el-dropdown-item>
                  <el-dropdown-item command="物">物</el-dropdown-item>
                  <el-dropdown-item command="机构">机构</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown> -->

                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="刷新"
                    placement="top"
                  >
                    <el-button
                      size="small"
                      icon="el-icon-refresh"
                      @click="refresh"
                    ></el-button>
                  </el-tooltip>
                </div>

                <el-dropdown
                  style="margin-left: 10px"
                  size="small"
                  type="primary"
                  trigger="click"
                  @command="According"
                >
                  <el-button type="primary" size="small">
                    菜单<i class="el-icon-arrow-down el-icon--right"></i
                  ></el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @command="According" command="a"
                      >显示字框</el-dropdown-item
                    >
                    <!-- <el-dropdown-item command="b">保存切框</el-dropdown-item> -->
                    <!-- <el-dropdown-item divided command="c">造字</el-dropdown-item> -->
                    <el-dropdown-item divided command="d"
                      >红色字体</el-dropdown-item
                    >
                    <!-- <el-dropdown-item command="e">显示可疑字</el-dropdown-item>
                <el-dropdown-item command="f">显示特小字符</el-dropdown-item>
                <el-dropdown-item command="g">清除空白字符</el-dropdown-item> -->
                    <el-dropdown-item divided command="黑体"
                      >黑体</el-dropdown-item
                    >
                    <el-dropdown-item divided command="楷体"
                      >楷体</el-dropdown-item
                    >
                    <el-dropdown-item divided command="宋体"
                      >宋体</el-dropdown-item
                    >
                    <el-dropdown-item divided command="仿宋"
                      >仿宋</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>

                <div v-if="taskNo.substring(0, 2) == 'DY' || taskNo == ''">
                  <el-button
                    @click="PreviousPage(0)"
                    style="margin-left: 5px"
                    type="primary"
                    size="small"
                    >上一页</el-button
                  >
                  <el-button
                    @click="PreviousPage(1)"
                    style="margin-left: 5px"
                    type="primary"
                    size="small"
                    >下一页</el-button
                  >

                  <el-button
                    style="margin-left: 5px"
                    type="danger"
                    size="small"
                    @click="DeleteSingledialogVisible = true"
                  >
                    删除</el-button
                  >
                </div>

                <!-- <el-button style="margin-left: 15px" type="success" size="small"
              >谱系完成</el-button
            >

            <el-button
              style="margin-left: 5px"
              type="success"
              size="small"
              @click="SecondRecognition"
              >送入二次识别</el-button
            >-->
              </div>
              <!-- 校对  -->
              <div class="proofread" @mousedown="down" ref="boxx">
                <!-- 左盒子 -->
                <div
                  class="boxx-left"
                  ref="boxxleft"
                  @mouseover="changeFlag(false)"
                  @scroll="boxscroll($event)"
                >
                  <div>
                    <div
                      ref="boxleft"
                      style="
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        transform: translate(0px, 0px) scale(0.310719);
                      "
                    >
                      <div ref="copybox" class="copybox"></div>
                      <!-- 左侧画框 -->
                      <!-- :ref="'dropbox' + 'left' + index" -->
                      <div
                        style="zindex: -1"
                        ref="dropbox"
                        @click.stop="dropcancel"
                        @mousedown="dropboxx"
                        @contextmenu.prevent="dropclick"
                      >
                        <!-- 拖拽圆点右下 -->
                        <div
                          class="dropcircle-rightbottom"
                          @mousedown="circleclick"
                        ></div>

                        <!-- 左上盒子 -->
                        <div class="lefttopbox" ref="lefttopbox"></div>
                        <!-- 左上按钮 -->
                        <div
                          class="dropcircle-lefttop"
                          @mousedown="circleclickcomment($event, 'lefttopbox')"
                        ></div>
                        <!-- 左下角盒子 -->
                        <div class="leftbottombox" ref="leftbottombox"></div>
                        <!-- 左下按钮 -->
                        <div
                          class="dropcircle-leftbottom"
                          @mousedown="
                            circleclickcomment($event, 'leftbottombox')
                          "
                        ></div>
                        <!-- 右上角盒子 -->
                        <div class="rightttopbox" ref="righttopbox"></div>
                        <!-- 右上角按钮 -->
                        <div
                          class="dropcircle-righttop"
                          @mousedown="circleclickcomment($event, 'righttopbox')"
                        ></div>

                        <div class="dropwindow" ref="dropopen" id="dropopen">
                          <!-- <div class="drop-list">作为图像</div> -->
                          <!-- <div class="drop-list" @click="CutBoxVisible = true">
                        作为切框
                      </div> -->
                          <div class="drop-list" @click="canceldrop">
                            取消选区
                          </div>
                        </div>
                      </div>

                      <div
                        v-if="show != 2 && filename"
                        ref="leftcase"
                        v-loading="loading"
                        class="box-left"
                        :style="{
                          'background-image':
                            ispictureFileName == false
                              ? `url('${baseURL}api/fileinfo/image/GetOriImage?filename=${filename}&ocrSource=${
                                  ocrSource == 1 ? 1 : 2
                                }')`
                              : `url(${pictureFileName})`,
                          width: pagex + 'px',
                          height: pagey + 'px',
                          left: convertRect(filenameSize)[0].left + 'px',
                          top: convertRect(filenameSize)[0].top + 'px',
                          'background-repeat': 'no-repeat',
                        }"
                        @click="leftmenu"
                        @contextmenu.prevent="leftbtn"
                      >
                        <!-- 选框列表 -->
                        <div
                          ref="boxleftfont"
                          class="dropimglist"
                          :class="{
                            slectbox: slect,
                            ImghoverColor: index == hoverIndex,
                          }"
                          :style="{
                            left: convertRect(item.rcInImage)[0].x + 'px',
                            top: convertRect(item.rcInImage)[0].y + 'px',
                            width: convertRect(item.rcInImage)[0].width + 'px',
                            height:
                              convertRect(item.rcInImage)[0].height + 'px',
                          }"
                          v-for="(item, index) in proofreadList"
                          :key="item.id"
                        >
                          <!-- {{item.code}} -->
                        </div>
                        <!--                                              <div class="search-result-coordinates" ref="search-result"></div>-->
                      </div>
                    </div>
                  </div>
                </div>
                <el-image
                  v-show="false"
                  :src="`${baseURL}api/fileinfo/image/GetOriImage?filename=${filename}&ocrSource=${
                    ocrSource == 1 ? 1 : 2
                  }`"
                  id="oImg"
                  alt=""
                  @load="loadimg"
                />
                <!-- 右盒子 -->
                <div
                  class="boxx-right"
                  ref="boxxright"
                  @mouseover="changeFlag(true)"
                  @scroll="box1scroll($event)"
                >
                  <div
                    ref="boxright"
                    style="
                      position: absolute;
                      top: 0px;
                      left: 0px;
                      transform: translate(0px, 0px) scale(0.310719);
                    "
                  >
                    <!-- 拆字组合 -->
                    <div ref="rightdropbox" style="opacity: 0">
                      <!-- 左上盒子 -->
                      <div class="lefttopbox" ref="lefttopbox"></div>
                      <!-- 左上按钮 -->
                      <div
                        style="
                          width: 25px;
                          height: 25px;
                          left: -11px;
                          top: -10px;
                        "
                        ref="cirledrop1"
                        class="dropcircle-lefttop"
                        @mousedown="splitclickcomment($event, 'lefttopbox')"
                      ></div>
                      <!-- 左下角盒子 -->
                      <div class="leftbottombox" ref="leftbottombox"></div>
                      <!-- 左下按钮 -->
                      <div
                        style="
                          width: 25px;
                          height: 25px;
                          left: -11px;
                          bottom: -23px;
                        "
                        ref="cirledrop2"
                        class="dropcircle-leftbottom"
                        @mousedown="splitclickcomment($event, 'leftbottombox')"
                      ></div>
                      <!-- 右上角盒子 -->
                      <div class="rightttopbox" ref="righttopbox"></div>
                      <!-- 右上角按钮 -->
                      <div
                        style="
                          width: 25px;
                          height: 25px;
                          right: -25px;
                          bottom: 20px;
                        "
                        ref="cirledrop3"
                        class="dropcircle-righttop"
                        @mousedown="splitclickcomment($event, 'righttopbox')"
                      ></div>
                      <!-- 右下角盒子 -->
                      <div class="rightbottombox" ref="rightbottombox"></div>
                      <!-- 右下角按钮 -->
                      <div
                        style="
                          right: -25px;
                          bottom: -20px;
                          width: 25px;
                          height: 25px;
                        "
                        ref="cirledrop4"
                        class="dropcircle-rightbottom"
                        @mousedown="splitclickcomment($event, 'rightbottombox')"
                      ></div>
                      <!-- 输入框 -->
                      <div
                        ref="group"
                        class="groupinput"
                        contenteditable="plaintext-only"
                        @mousedown="groupdown"
                        @mouseup="groupup"
                        @input="plaintextbtn"
                      ></div>

                      <!-- 操作按钮 -->
                      <div class="group-button" ref="groupbtn">
                        <el-input
                          @input="inputbtn"
                          v-model="inputsize"
                          placeholder="请输入内容"
                        >
                        </el-input>
                        <el-button
                          type="primary"
                          style="margin-top: 5px"
                          @click="combination('union')"
                          >组合</el-button
                        >
                        <el-button
                          type="danger"
                          style="margin-top: 5px"
                          @click="combination('split')"
                          >拆解</el-button
                        >
                        <el-button
                          @click="savecombination"
                          type="success"
                          style="margin-top: 5px"
                          >保存</el-button
                        >
                        <el-button style="margin-top: 5px" @click="closegroup"
                          >取消</el-button
                        >
                      </div>
                    </div>
                    <div ref="dropbox1">
                      <!-- 拖拽圆点-右侧 -->
                      <div class="dropcircle-rightbottom1"></div>
                    </div>
                    <div
                      v-loading="loading"
                      element-loading-background="rgba(255,255,255, 0.5)"
                      ref="rightcase"
                      class="box-right"
                      @click.stop="rightmenu"
                      @contextmenu.prevent="rightbtn"
                      :style="{
                        width: pagex + 'px',
                        height: pagey + 'px',
                      }"
                    >
                      <div
                        ref="boxleftfont"
                        class="dropimglist"
                        :class="{
                          slectbox: slect,
                        }"
                        :style="{
                          left: convertRect(item.rcInImage)[0].x + 'px',
                          top: convertRect(item.rcInImage)[0].y + 'px',
                          width: convertRect(item.rcInImage)[0].width + 'px',
                          height: convertRect(item.rcInImage)[0].height + 'px',
                        }"
                        v-for="item in proofreadList"
                        :key="item.id"
                      ></div>
                      <div class="leftmask" ref="leftmask">
                        <!-- 字体列表 -->
                        <div>
                          <div
                            class="box-right-font"
                            ref="boxrightfont"
                            :class="{
                              hoverColor: index == hoverIndex,
                              downColor: windowindex == item._id,
                              caseColor: codefontList.includes(item._id),
                            }"
                            @mouseover="hoverIndex = index"
                            @mouseout="hoverIndex = -1"
                            @click.stop="fontlistbtn($event, index, item)"
                            @contextmenu.prevent.stop="
                              rightfontbtn($event, item, index)
                            "
                            :style="{
                              left: convertRect(item.rcInImage)[0].x + 'px',
                              top: convertRect(item.rcInImage)[0].y + 'px',
                              fontFamily: `KaiTi`,
                              fontSize:
                                convertRect(item.rcInImage)[0].height + 'px',
                              width:
                                convertRect(item.rcInImage)[0].width + 'px',
                              height:
                                convertRect(item.rcInImage)[0].height + 'px',
                            }"
                            v-for="(item, index) in proofreadList"
                            :key="item.id"
                          >
                            <span
                              @mousedown="
                                codefont(
                                  $event,
                                  item.code,
                                  item._id,
                                  convertRect(item.rcInImage)[0].x,
                                  convertRect(item.rcInImage)[0].y,
                                  item,
                                  index
                                )
                              "
                              @mouseup="codefontmouseup"
                              v-if="(item.code || '').split('')[0] != '['"
                            >
                              {{ item.code }}
                            </span>

                            <!-- -------------------------- -->
                            <el-image
                              v-if="(item.code || '').split('')[0] == '['"
                              style="width: 80px; height: 80px"
                              :src="`${baseurl}api/proofread/WordBuilder/GetWordImageByCode?code=${item.code}`"
                            >
                            </el-image>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 相似字列表 -->
                <div
                  style="left: 5px"
                  class="box-right-font-window"
                  id="fontwindow"
                  v-show="windowindex == fontlist._id"
                >
                  <el-input
                    ref="saveTagInput"
                    @focus="focusclick"
                    @blur="blurclick"
                    @change="
                      submitfont(
                        fontlist.code,
                        fontlist._id,
                        fontlist.rcInImage
                      )
                    "
                    style="width: 160px"
                    v-model="unfold"
                    placeholder="请输入内容"
                  ></el-input>
                  <el-button
                    style="position: absolute; right: -55px; margin-top: 0px"
                    type="primary"
                    size="mini"
                    @click="
                      submitfont(
                        fontlist.code,
                        fontlist._id,
                        fontlist.rcInImage
                      )
                    "
                    >提交</el-button
                  >
                  <el-button
                    style="position: absolute; right: -55px; margin-top: 30px"
                    type="danger"
                    size="mini"
                    @click="DeleteThefont"
                    >删除</el-button
                  >
                  <el-button
                    style="position: absolute; right: -55px; margin-top: 60px"
                    size="mini"
                    @click.stop="cancelsimilar()"
                    >取消</el-button
                  >
                  <!-- <el-button style="position: absolute; right: -55px; margin-top: 90px" type="warning"
                                        size="mini" @click="makefont">造字</el-button> -->
                  <div class="window-ctn">
                    <div class="window-ctn-list">
                      <div
                        class="window-list-box"
                        v-for="(smil, index) in fontlist.similar"
                        :key="index"
                        @click="
                          smilfont(smil, fontlist._id, fontlist.rcInImage)
                        "
                      >
                        {{ smil }}
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 右击盒子-右侧 -->
                <div class="right-btn-box" ref="rightbox">
                  <div class="right-list" @click="cleararea">删除所选</div>
                  <div class="right-list" @click="refresh">刷新</div>
                  <div class="right-list" @click="splitgroup">拆解组合</div>
                  <div class="right-list" @click.stop="clearchoose">
                    清除选区
                  </div>
                  <div class="right-list" @click="showgraph">生成段落</div>
                  <div class="right-list" @click.stop="clearcopy">取消复制</div>
                </div>
                <!-- 右击字体盒子-右侧 -->
                <div class="right-btn-box" ref="rightfontbox">
                  <div class="right-list" @click="DeleteThefont">
                    删除该字(图像)
                  </div>
                  <div class="right-list" @click="Afterbtn">置底</div>
                  <div class="right-list" @click="Beforebtn">置前</div>
                </div>
                <!-- 右击盒子-左侧 -->
                <div class="left-btn-box" ref="lefttbox" @click.stop="">
                  <div class="right-list1" @click="rote">顺时针旋转90°</div>
                  <div class="right-list1" @click="lrote">逆时针旋转90°</div>
                </div>
              </div>
            </div>
            <!-- 是否删除所选字 -->
            <el-dialog
              title="提示"
              :visible.sync="FontVisible"
              :close-on-click-modal="false"
              :show-close="false"
              top="30vh"
              width="15%"
            >
              <span>确定要删除所选的字吗？</span>
              <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="FontVisible = false"
                  >取 消</el-button
                >
                <el-button size="mini" type="primary" @click="DelFontVisible"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 是否删除 -->
            <el-dialog
              title="提示"
              :visible.sync="DeleteSingledialogVisible"
              :close-on-click-modal="false"
              width="15%"
            >
              <span>是否删除</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="DeleteSingledialogVisible = false"
                  >取 消</el-button
                >
                <el-button type="primary" @click="DeleteSingle"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- 是否作为切框 -->
            <el-dialog
              title="提示"
              :visible.sync="CutBoxVisible"
              :close-on-click-modal="false"
              width="15%"
              top="30vh"
            >
              <span>确定将当前选区作为切框？</span>
              <span slot="footer" class="dialog-footer">
                <el-button @click="CutBoxVisible = false">取 消</el-button>
                <el-button type="primary" @click="ConfirmCutBox"
                  >确 定</el-button
                >
              </span>
            </el-dialog>
            <!-- ---------右侧信息模块 -->
            <singlemsg
              :FineParticle="FineParticle"
              :activeName="activeName"
              :sourceID="sourceID"
              :singlefrom="singlefrom"
              :documentListDto="documentListDto"
              @updatemsg="updatemsg"
              @changeurl="changeurl(arguments)"
              @reload="singledetail"
              @coordinate="coordinate"
              :docId="docId"
              @setTotals="setTotals"
            ></singlemsg>
          </div>

          <!-- 造字组件 -->
          <wordcard
            :wordVisible="wordVisible"
            @closer="wordcloser"
            @changefont="changefont"
          ></wordcard>
          <!-- 生成段落弹出组件 -->
          <parphy
            :graphVisible="graphVisible"
            :singlefrom="singlefrom"
            @closer="wordcloser"
          ></parphy>
        </el-card>
      </div>
    </el-main>

    <el-footer>
      <span>福建两岸信息技术有限公司</span>
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >闽ICP备17021731号-9</a
      >
    </el-footer>
  </el-container>
</template>

<script>
import singlemsg from "@/components/BatchAdd/SingleMsg";
import wordcard from "@/components/BatchAdd/MakeWord";
import parphy from "@/components/BatchAdd/paragraph";
export default {
  components: {
    singlemsg,
    wordcard,
    parphy,
  },
  data() {
    return {
      Totals: "",
      docId: "",
      smallPic: 0, // 图片过小
      coordinateList: null, // 检索后的坐标信息
      CutBoxVisible: false,
      CutBoxmove: false,
      //判断按钮是否聚集
      isautofocus: false,
      Fineisautofocus: false,
      activeName: "first",
      editName: "first",
      curren: 0,
      remark: "",
      form: {
        name: "",
      },
      // 单页校对表单信息
      singlefrom: {
        documentListDto: {},
      },
      documentListDto: {
        documentTypeText: "", // 字符串文件类型
        alternative: "string",
        audience: "string",
        cdoi: "string",
        classification: "string",
        contentForm: "string",
        deleted: false,
        documentType: 134,
        edition: "string",
        format: "string",
        id: "f438509c-3f54-e703-4a90-3a01554369a5",
        isbn: "string",
        keyword: "string",
        language: "string",
        mediaType: "string",
        notes: "string",
        organisation: "1300",
        owner: "string",
        placeOfPublication: "string",
        publisher: "string",
        recordDate: "string",
        relation: "string",
        // sourceID: "130010020220000001",
        spatial: "string",
        temporal: "string",
        title: "string",
        uri: "string",
      },
      newsinglefrom: {},
      show: "",
      radio: "1",
      radio1: "",
      //段落-从左往右-从右往左
      rdlr: "1",
      rd: "",
      hiddenback: false,
      hiddenproofread: false,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      flag: false,
      input: "",
      dialogVisible: false,
      AuditdialogVisible: false,
      wordVisible: false,
      graphVisible: false,
      //是否送入二次识别
      SecondVisible: false,
      //是否删除所选字
      FontVisible: false,
      n: 90,
      m: 0,
      threelist: [
        {
          label: "一级 1",
          children: [
            {
              label: "二级 1-1",
              children: [
                {
                  label: "三级 1-1-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 2",
          children: [
            {
              label: "二级 2-1",
              children: [
                {
                  label: "三级 2-1-1",
                },
              ],
            },
            {
              label: "二级 2-2",
              children: [
                {
                  label: "三级 2-2-1",
                },
              ],
            },
          ],
        },
        {
          label: "一级 3",
          children: [
            {
              label: "二级 3-1",
              children: [
                {
                  label: "三级 3-1-1",
                },
              ],
            },
            {
              label: "二级 3-2",
              children: [
                {
                  label: "三级 3-2-1",
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      keylist: [],
      threeid: "",
      Xvalue: 0,
      Yvalue: 0,
      dfocus: false,
      dfocus1: false,
      //造字编码
      unfold: "",
      //段落文字内容
      content: "",
      //期刊类型100 图书,110 期刊,120 报纸,131 方志、132 历史档案、133 谱牒、134 古籍，150 音频，160 视频
      documentType: "",
      TypeName: "",
      // 章节期刊列表
      getChapterList: [],
      //信息块通用字段
      GeneralField: null,
      //校对字体列表
      proofreadList: [],
      //校对盒子尺寸
      pagex: "",
      pagey: "",
      //校对原图id
      filename: "",
      //校对原图尺寸
      filenameSize: "",
      hoverIndex: -1,
      windowindex: -1,
      baseurl: this.baseURL,
      slect: false,
      // 当前详情页id
      detailid: "123456",
      sourceID: "",
      ocrSource: "",
      offsetPos: {},
      b: {},
      dropmode: "",
      keyon: false,
      dropx: "",
      dropy: "",
      ofdropx: "",
      ofdropy: "",
      a: true,
      zoomscale: 0.310719,
      zoomclient: 0,
      loading: false,
      // 是否可拖拽
      isdown: true,
      //图片拖拽距离存储
      distanceX: 0,
      distanceY: 0,
      //盒子拖拽距离存储
      dropboxX: 0,
      dropboxY: 0,
      isred: false,
      //区间内的字体
      codelist: [],
      //输入框字体大小
      inputsize: 50,
      x: "[林亅一乚1]",
      // 单击汉字列表id集合
      testfontList: [],
      codefontList: [],
      wordfontList: [],
      // 单击汉字列表字集合
      codefontFontList: "",
      //单击汉字列表坐标集合
      rectInImage: {
        x: "",
        y: "",
      },
      MemberWordModelDto: {},
      FinegranularityModelDto: {},
      FinegranularityLits: [],
      MultiSelectFinegranularityLits: [],
      //已绑定的族谱成员列表
      MembersFamilyList: [],
      //默认族谱成员列表
      DfaultMembersFamilyList: [],
      //切换到族谱成员
      MembersFamily: false,
      //切换到细颗粒度界面
      memberParticleVisible: false,
      //解除绑定dialog
      BindingVisible: false,
      //谱系成员id
      selectId: "",
      //是否删除世系成员
      DeletVisible: false,
      words: [
        {
          rcInPage: "rcInPage",
          rcInImage: "rcInImage",
          rectInImage: {
            location: {
              isEmpty: true,
              x: 1,
              y: 2,
            },
            size: {
              isEmpty: true,
              width: 11,
              height: 22,
            },
            x: "",
            y: "",
            width: 123,
            height: 123,
            left: 123,
            top: 321,
            right: 333,
            bottom: 1,
            isEmpty: true,
          },
          code: "code",
          codeCorrect: "codeCorrect",
          singcontent: "",
        },
      ],
      bradio: "1",
      radioLabel: "1",
      rcInImageList: [],
      //已标记列表
      tagslist: [],
      //无关系成员列表
      Norelationships: [],
      singlist: [],
      singlistindex: "",
      //字体id
      boxfontid: "",
      keymoveX: "",
      keymoveY: "",
      charID: "",
      RrcInImage: "",
      RrcInImageWidth: "",
      RrcInImageHeight: "",
      selectedFontList: [],
      //细颗粒度多选-所选字
      MultiSelectParticle: [],
      plaintext: "",
      // 单页完成
      FinishSingledialogVisible: false,
      FinishIsshow: false,
      // 全本完成
      AllFinishSingledialogVisible: false,
      DeleteSingledialogVisible: false,
      FinishSingledialogVisibleType: "",
      AllFinishSingledialogVisibleType: "",
      //人地物事机构关键字
      FineParticle: "",
      //初始放大缩小比例值
      ZoomInOnLeft: "",
      ZoomInOnTop: "",
      //更新后放大缩小比例值
      NewZoomInOnLeft: "",
      NewZoomInOnTop: "",
      // 单击字体列表获取到的数据
      fontlist: {},
      //字体优先级
      fontindex: "",
      boxrightfontref: "",
      //细颗粒度当前成员项
      ParticleAccordingIndex: "",
      FineParticleName: "",
      ParticleList: [],
      ParticleCurrent: "",
      Innername: "",
      Innerhtml: "",
      membersmsg: "",
      nameParticleVisible: false,
      selectId: "",
      selectFullName: "",
      bgColor: `red`,
      RightMembersMsg: "",
      MoveState: "",
      dropboxxnum: [],
      taskNo: "",
      movex: "",
      movey: "",
      dataUrl: "",
      pictureFileName: "",
      ispictureFileName: false,
      selectedChars: [],
      newWord: "",
      ccx: "",
      ccy: "",
      documentTitles: {},
      splitgroupshow: false,
      movedrop: "",
      singleeditName: "",
    };
  },
  provide() {
    return {
      // 章节期刊列表
      getChapterList: this,
    };
  },
  computed: {
    ProofreadList() {
      return this.proofreadList;
    },

    convertRect() {
      return function (info) {
        var rect = [{ x: 0, y: 0, width: 0, height: 0 }];
        // console.log('bbbbbbbbbbbbbbbbbb',info)

        if (!info) {
          return rect;
        }
        var infos = info.split(",");
        if (infos.length == 4) {
          rect[0].x = parseInt(infos[0]);
          rect[0].y = parseInt(infos[1]);
          rect[0].width = parseInt(infos[2]) - rect[0].x;
          rect[0].height = parseInt(infos[3]) - rect[0].y;
        }
        // console.log(rect)
        return rect;
      };
    },

    // 动态添加选中的颜色
    addcolor() {
      return function () {
        // 动态添加选中的颜色
        let boxrightfont = this.$refs.boxrightfont;

        // console.log(boxrightfont);
        for (let q of boxrightfont) {
          if (
            q.offsetLeft > this.b.x - 50 &&
            q.offsetLeft < this.offsetPos.x &&
            q.offsetTop > this.b.y - 70 &&
            q.offsetTop < this.offsetPos.y
          ) {
            // font.push(q.getElementsByTagName("p")[0].innerText)
            // console.log(font)
            q.style.background = `#f0ad4e`;
          } else {
            q.style.background = ``;
          }
        }
      };
    },
    // 动态添加所选字
    addfont() {
      return function () {
        //计算字体是否在当前选区内

        let list = [];
        let item = [];
        for (let p of this.proofreadList) {
          //判断选区区间
          if (
            p.rcInImage.split(",")[0] > this.b.x - 50 &&
            p.rcInImage.split(",")[0] < this.offsetPos.x &&
            p.rcInImage.split(",")[1] > this.b.y - 70 &&
            p.rcInImage.split(",")[1] < this.offsetPos.y
          ) {
            // console.log(p);
            list.push(p._id);
            item.push(p);
            // console.log(item);
          }
        }
        for (let j of this.FinegranularityLits) {
          //判断选区区间
          if (
            j.rcInImage.split(",")[0] > this.b.x - 50 &&
            j.rcInImage.split(",")[0] < this.offsetPos.x &&
            j.rcInImage.split(",")[1] > this.b.y - 70 &&
            j.rcInImage.split(",")[1] < this.offsetPos.y
          ) {
          } else {
            this.clearfont();
          }
        }
        if (item.length > 0) {
          //重新生成段落
          this.$request({
            url: "/api/proofread/user-center/build-paragraph-content",
            method: "POST",
            data: {
              sourceID: this.singlefrom.sourceID,
              ocrInfoID: this.singlefrom.id,
              fdCharDtos: [], // item,
              holdSpace: true,
              autoWriteModel: false,
              landscape: false,
            },
          })
            .then((res) => {
              // console.log("res", res);
              //  this.codefontFontList = [...new Set(res.content)];
              this.codefontFontList = res.content;
            })
            .catch((e) => {
              console.log("e", e);
            });
        }

        // ------------------------

        this.selectedFontList = [...new Set(list)];
        this.MultiSelectFinegranularityLits = [];
        this.MultiSelectFinegranularityLits = [...new Set(item)];
      };
    },
  },
  watch: {
    slect(e) {
      if (e) {
        if (this.show != 2) {
          for (let i of this.$refs.boxrightfont) {
            i.style.border = `${1}px solid red`;
          }
        }
      } else {
        for (let i of this.$refs.boxrightfont) {
          i.style.border = `none`;
        }
      }
    },
    CutBoxmove(e) {
      if (e == true) {
        let dropbox = this.$refs.dropbox;
        console.log(dropbox.offsetLeft, dropbox.offsetTop);
        dropbox.style.left = dropbox.offsetLeft + "px";
        dropbox.style.top = dropbox.offsetTop + "px";
      }
    },
    hiddenback(e) {
      let rightcase = this.$refs.rightcase;
      if (e) {
        rightcase.style.backgroundImage = ``;
      } else {
        if (this.ispictureFileName) {
          // console.log(this.pictureFileName);
          rightcase.style.backgroundImage = `url('${this.pictureFileName}')`;
        } else {
          rightcase.style.backgroundImage = `url('${
            this.baseURL
          }api/fileinfo/image/GetOriImage?filename=${this.filename}&ocrSource=${
            this.ocrSource == 1 ? 1 : 2
          }')`;
        }
      }
    },
    hiddenproofread(e) {
      if (e) {
        for (let i of this.$refs.boxrightfont) {
          i.style.display = `none`;
        }
      } else {
        for (let i of this.$refs.boxrightfont) {
          i.style.display = `block`;
        }
      }
    },
    dropmode(val) {
      console.log(val);
    },
    // 滑块左右
    Xvalue(val) {
      // console.log(val);
      let leftmask = this.$refs.leftmask;
      leftmask.style.left = `${val}px`;
    },
    Yvalue(val) {
      // console.log(val);
      let leftmask = this.$refs.leftmask;
      leftmask.style.top = `${val}px`;
    },
    bradio(val) {
      console.log(val);
      this.radioLabel = val;
    },
    singlefrom(val) {
      console.log(val, "singlefrom监听");
    },
  },
  mounted() {
    // console.log(this.$refs.leftcase, '内容盒子')
    //  console.log(this.x.split(''))
    // console.log(this.x.split(',').includes(""))
    //线形图组件
    console.log(this.sourceID + "图片id");
    console.log("detailid", this.detailid);
    // console.log('单页校对列表',this.singlist)
    // console.log('单页校对列表当前下标',this.singlistindex)
    // console.log(this.filename);
    // 单页校对详情
    if (this.$route.query.taskNo) {
      this.taskNo = this.$route.query.taskNo;
    }
    console.log(this.$route.query.taskNo, "this.$route.query.taskNo");
    this.singledetail(this.$route.query.taskNo);

    // this.convertRect();
    //画框---------------------------
    this.moveleft();
    this.moveright();
    //监听键盘事件----------------------------
    window.addEventListener("keydown", this.handleEvent);
    window.addEventListener("keyup", this.handleUPEvent);
    // window.addEventListener("keydown", this.moveleft);
    // window.addEventListener("keydown", this.$throttle(this.moveright, 500));
    //  this.dropboxx()
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleEvent); // 在页面销毁的时候记得解除
    window.removeEventListener("keydown", this.moveleft);
    window.removeEventListener("keydown", this.moveright);
  },
  created() {
    this.singlistindex = this.$route.query.index;
    // this.singlist = JSON.parse(this.$route.query.item);
    this.sourceID = this.$route.query.sourceID;
    this.detailid = this.$route.query.id;
  },
  methods: {
    setTotals(Totals) {
      this.Totals = Totals;
    },
    // 全局检索后标出对应的关键字
    coordinate(data) {
      this.coordinateList = data;
      console.log(this.coordinateList, "coorii");
      // 关键字框的坐标的请求参数
      const position = this.coordinateList
        .map((v) => v.x + "," + v.y + "," + v.right + "," + v.bottom)
        .reduce((prev, next) => {
          return prev + "&rects=" + next;
        }, "");
      console.log(position, "position");
      this.$refs.leftcase.style.backgroundImage =
        this.ispictureFileName === false
          ? `url('/api/fileinfo/image/GetOriImage?filename=${
              this.filename
            }&ocrSource=${this.ocrSource == 1 ? 1 : 2}${position}')`
          : `url(${pictureFileName})`;
    },
    //键盘抬起事件
    handleUPEvent(e) {
      // console.log("键盘抬起了");
      document.onmousewheel = null;
    },
    //键盘按下事件
    handleEvent(e) {
      // console.log("键盘按下了");
      let that = this;
      if (e.altKey == true) {
        console.log("你按了alt");
        document.onmousewheel = function (event) {
          console.log(event.wheelDelta);
          if (event.wheelDelta > 0) {
            that.amplification(0.03);
            console.log("向上滚动");
          }
          if (event.wheelDelta < 0) {
            that.narrow(0.03);
            console.log("向下滚动");
          }
        };
      }
      // console.log(e);
      if (e.key == "Escape") {
        this.clearcopy();
      }

      if (this.charID) {
        if (e.code == "KeyC" && e.ctrlKey) {
          console.log("ctrl+c");
          for (let i of this.$refs.boxrightfont) {
            i.style.pointerEvents = `none`;
          }

          this.$refs.boxright.addEventListener("mousemove", this.keymove);
        }

        if (e.code == "KeyV" && e.ctrlKey) {
          console.log("ctrl+v");
          this.$request({
            url: `/api/proofread/user-center/copy-word`,
            method: "POST",
            data: {
              copyCharId: this.charID,
              ocrInfoID: this.detailid,
              rcInImage: `${this.keymoveX},${this.keymoveY},${
                this.keymoveX + this.RrcInImageWidth
              },${this.keymoveY + this.RrcInImageHeight}`,
              TaskNo: this.taskNo,
              ocrSource: this.ocrSource,
            },
          })
            .then((res) => {
              // this.clearcopy();

              for (let i of this.$refs.boxrightfont) {
                i.style.pointerEvents = `auto`;
              }
              this.singledetail(this.taskNo);
            })
            .catch((e) => {
              console.log("e", e);
            });
        }
      }
    },
    //取消复制
    clearcopy() {
      for (let i of this.$refs.boxrightfont) {
        i.style.pointerEvents = `auto`;
      }
      console.log("取消复制");
      this.$refs.boxright.removeEventListener("mousemove", this.keymove);
      this.$refs.rightbox.style.display = "none";
      this.$refs.copybox.style.display = "none";
    },
    keymove(e) {
      // console.log(e.clientX,e.clientY)
      this.keymoveX = e.offsetX;
      this.keymoveY = e.offsetY;
      let copybox = this.$refs.copybox;
      copybox.style.display = "block";
      copybox.style.left = e.offsetX + "px";
      copybox.style.top = e.offsetY + "px";
      // console.log(e.offsetX, e.offsetY);
    },

    // 菜单menu功能
    According(e) {
      console.log(e);
      if (e == "a") {
        this.slect = !this.slect;
      }
      if (e == "c") {
        this.wordVisible = true;
      }
      if (e == "d") {
        this.isred = !this.isred;
        if (this.isred) {
          let boxrightfont = this.$refs.boxrightfont;
          for (let p of boxrightfont) {
            p.style.color = `red`;
          }
        } else {
          let boxrightfont = this.$refs.boxrightfont;
          for (let p of boxrightfont) {
            p.style.color = `#337ab7`;
          }
        }
      }
      if (e == "黑体") {
        let boxrightfont = this.$refs.boxrightfont;
        for (let p of boxrightfont) {
          p.style.fontFamily = `SimHei`;
        }
      }
      if (e == "楷体") {
        let boxrightfont = this.$refs.boxrightfont;
        for (let p of boxrightfont) {
          p.style.fontFamily = `KaiTi`;
        }
      }
      if (e == "宋体") {
        let boxrightfont = this.$refs.boxrightfont;
        for (let p of boxrightfont) {
          p.style.fontFamily = `SimSun`;
        }
      }
      if (e == "仿宋") {
        let boxrightfont = this.$refs.boxrightfont;
        for (let p of boxrightfont) {
          p.style.fontFamily = `FangSong`;
        }
      }
    },
    //预加载图片
    loadimg(e) {
      // 兼容火狐浏览器
      const path = e.path || (e.composedPath && e.composedPath());
      // console.log("图片宽高", e.path[0].naturalWidth, e.path[0].naturalHeight);
      // console.log("图片宽高", path[0].naturalWidth, path[0].naturalHeight);
      // setTimeout((p) => {
      //     this.smallPic = 0
      //     this.zoomscale = 0.310719
      //     this.$refs.boxleft.style.transform = `scale(${this.zoomscale})`;
      //     this.$refs.boxright.style.transform = `scale(${this.zoomscale})`;
      //     if(e.path[0].naturalWidth <= 1124 || te.path[0].naturalHeight <= 1591) {
      //         this.smallPic = 0
      //         this.zoomscale = 0.310719
      //         this.smallPic += 0.4
      //         this.zoomscale += this.smallPic
      //         this.$refs.boxleft.style.transform = `scale(${this.zoomscale})`;
      //         this.$refs.boxright.style.transform = `scale(${this.zoomscale})`;
      //     }
      //     this.smallPic = 0
      //     this.zoomscale = 0.310719
      //     this.pagex = e.path[0].naturalWidth;
      //     this.pagey = e.path[0].naturalHeight;
      // }, 500);
      this.$nextTick(() => {
        this.smallPic = 0;
        this.zoomscale = 0.310719;
        this.$refs.boxleft.style.transform = `scale(${this.zoomscale})`;
        this.$refs.boxright.style.transform = `scale(${this.zoomscale})`;
        if (path[0].naturalWidth <= 1124 || path[0].naturalHeight <= 1591) {
          this.smallPic = 0;
          this.zoomscale = 0.310719;
          this.smallPic += 0.4;
          this.zoomscale += this.smallPic;
          this.$refs.boxleft.style.transform = `scale(${this.zoomscale})`;
          this.$refs.boxright.style.transform = `scale(${this.zoomscale})`;
        }
        this.pagex = path[0].naturalWidth;
        this.pagey = path[0].naturalHeight;
      });
    },
    //判断tif图片
    Tifimg(i) {
      //---------------------------判断tif图片
      let that = this;
      let img = that.$refs.img;
      let request = new XMLHttpRequest();
      let leftcase = that.$refs.leftcase;
      request.responseType = "arraybuffer";
      // request.open("get",`api/fileinfo/image/GetOriImage?filename=n4wVNGQabtJVXYkaGpgz3e1mcaGsQbc3lc xzhMjTvllzLvz8JlSGLQc02Cqvs0R//7wbBUULIc=`, true);
      request.open("get", i, true);
      request.onload = function () {
        var tiff = new Tiff({ buffer: request.response });
        that.pictureFileName = tiff.toDataURL();
        // console.log(this.pictureFileName);
        img.src = tiff.toDataURL(); // 转化成base64的api
        leftcase.style.backgroundImage = `url(${tiff.toDataURL()})`;
        // console.log("尺寸1", img.naturalWidth, img.naturalHeight);
        // console.log("尺寸2", img.offsetWidth, img.offsetHeight);
      };
      request.send(null);
    },
    // 右侧缩略图列表详情
    changeurl(val) {
      console.log(val[0], val[1] + "哈哈哈" + "子组件的id");
      this.detailid = val[0];
      this.singledetail(this.taskNo);
      // 去除字框
      if (this.slect) {
        this.slect = false;
      }

      // 解决拖不动的问题
      this.$refs.boxleft.style.top = "0px";
      this.$refs.boxleft.style.left = "0px";
      this.$refs.boxright.style.top = "0px";
      this.$refs.boxright.style.left = "0px";
    },
    popdetail() {
      this.dialogVisible = true;
    },
    handleClick(e) {
      console.log;
    },
    // 翻页
    PreviousPage(i) {
      this.$refs.boxleft.style.left = 0;
      this.$refs.boxright.style.left = 0;
      this.$refs.boxleft.style.top = 0;
      this.$refs.boxright.style.top = 0;

      if (this.slect) {
        this.slect = false;
      }

      this.$request({
        url: `/api/proofread/user-center/${this.detailid}/befor-or-next?opType=${i}&taskNo=${this.taskNo}`,
        method: "POST",
      })
        .then((res) => {
          // 更改当前地址参数
          let query = this.$router.history.current.query;
          let path = this.$router.history.current.path;
          console.log(query);
          console.log(path);
          this.$router.push({
            path: "/proofreading/singlePageDetail",
            query: {
              id: res.id,
              sourceID: res.sourceID,
              index: 0,
              ocrSource: res.ocrSource,
            },
          });
          // console.log("res", res);
          this.content = res.content;
          this.singlefrom = res;
          this.documentListDto = res.documentListDto;

          // this.newsinglefrom= Object.assign(this.singlefrom, res);
          this.documentType = res.documentListDto.documentType;
          this.GeneralField = res.documentListDto;
          this.proofreadList = res.chars;
          this.filenameSize = res.boxs[0].rcInImage;
          this.ocrSource = res.ocrSource;
          this.filename = res.pictureFullName;
          this.detailid = res.id;
          // 世系编辑字体选项数组清空
          this.MemberWordModelDto = [];

          this.codefontList = [];

          //获取族谱成员世系图
          // this.MembersLineageImg();
          this.canceldrop();
          // 如果字体红色继续保持
          if (this.isred) {
            setTimeout(() => {
              let boxrightfont = this.$refs.boxrightfont;
              for (let p of boxrightfont) {
                p.style.color = `red`;
              }
            }, 10);
          }
          if (this.show == 2) {
            this.$refs.rightcase.style.backgroundImage = `url('${
              this.baseURL
            }api/fileinfo/image/GetOriImage?filename=${
              this.filename
            }&ocrSource=${this.ocrSource == 1 ? 1 : 2}')`;
            this.$refs.rightcase.style.width = this.pagex + "px";
            this.$refs.rightcase.style.height = this.pagey + "px";
          }
          // return this.$message.success("翻页成功~");
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //单页完成按钮
    FinishSingledialogVisibleBtn(e) {
      this.FinishSingledialogVisibleType = e;
      this.FinishSingledialogVisible = true;
      this.FinishSingle();
    },
    //单页或取消完成
    FinishSingle() {
      this.FinishSingledialogVisible = false;
      //  return this.$message.success("单页完成~");
      if (this.FinishSingledialogVisibleType == "0") {
        this.FinishIsshow = true;
        this.$request({
          url: `/api/proofread/user-center/${this.detailid}/o-cRFlag?opType=0&taskNo=${this.taskNo}`,
          method: "PUT",
        })
          .then((res) => {
            console.log("res", res);
            this.singledetail(this.taskNo);
            return this.$message.success("单页完成~");
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
      if (this.FinishSingledialogVisibleType == "1") {
        this.FinishIsshow = false;
        this.$request({
          url: `/api/proofread/user-center/${this.detailid}/un-do-oCRFlag?opType=0&taskNo=${this.taskNo}`,
          method: "PUT",
        })
          .then((res) => {
            console.log("res", res);
            this.singledetail(this.taskNo);
            return this.$message.success("取消单页完成~");
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
    },
    //全本完成按钮
    AllFinishSingledialogVisibleBtn(e) {
      this.AllFinishSingledialogVisibleType = e;
      this.AllFinishSingledialogVisible = true;
      this.AllFinishSingle();
    },
    //全本完成
    AllFinishSingle() {
      this.AllFinishSingledialogVisible = false;
      //  return this.$message.success("全本完成~");
      if (this.AllFinishSingledialogVisibleType == "0") {
        this.$request({
          url: `/api/proofread/user-center/${this.detailid}/o-cRFlag?opType=1&taskNo=${this.taskNo}`,
          method: "PUT",
        })
          .then((res) => {
            // console.log("res", res);
            return this.$message.success("全本完成~");
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
      if (this.AllFinishSingledialogVisibleType == "1") {
        this.$request({
          url: `/api/proofread/user-center/${this.detailid}/un-do-oCRFlag?opType=1&taskNo=${this.taskNo}`,
          method: "PUT",
        })
          .then((res) => {
            // console.log("res", res);
            return this.$message.success("全本完成~");
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
    },
    //删除当前校对
    DeleteSingle() {
      this.DeleteSingledialogVisible = false;
      this.PreviousPage(1);
      this.$request({
        url: `/api/proofread/user-center/${this.detailid}/document-oCRInfo?taskNo=${this.taskNo}`,
        method: "DELETE",
      })
        .then((res) => {
          // console.log("res", res);
          // location.reload()

          return this.$message.success("删除成功~");
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //删除该字
    DeleteThefont() {
      console.log("删除该字");
      this.$request({
        url: `/api/proofread/user-center/har-info?OCRInfoID=${this.detailid}&Id=${this.boxfontid}&TaskNo=${this.taskNo}`,
        method: "DELETE",
      })
        .then((res) => {
          // console.log("res", res);
          this.singledetail(this.taskNo);
          this.$refs.rightfontbox.style.display = "none";
          this.windowindex = -1;
          return this.$message.success("删除成功~");
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    focusclick(e) {
      console.log(e);
      this.isdown = false;
    },
    blurclick(e) {
      setTimeout(() => {
        console.log(e);
        this.isdown = true;
      }, 100);
    },
    changeFlag(flag) {
      this.flag = flag;
    },
    nodeclick(e) {
      console.log(e + "check点击");
    },
    threeclick(e) {
      this.threeid = e.label;
      console.log(this.threeid);
      console.log(e + "名字点击");
    },
    switchmember() {
      this.MembersFamily = !this.MembersFamily;
    },
    Particlemember() {
      this.memberParticleVisible = !this.memberParticleVisible;
    },
    editClick() {},
    //   左滚动盒子
    boxscroll(e) {
      if (!this.flag) {
        this.$refs.boxxright.scrollTop = this.$refs.boxxleft.scrollTop;
      }
    },
    //右盒子滚动
    box1scroll(e) {
      if (this.flag) {
        this.$refs.boxxleft.scrollTop = this.$refs.boxxright.scrollTop;
      }
    },
    cClick(cindex) {
      this.curren = cindex;
    },
    radi(e) {
      // 切换复原
      this.$refs.boxleft.style.top = "0px";
      this.$refs.boxleft.style.left = "0px";
      this.$refs.boxright.style.top = "0px";
      this.$refs.boxright.style.left = "0px";
      this.Xvalue = 0;
      this.Yvalue = 0;
      if (e == 2) {
        this.$refs.boxxright.style.width = `100%`;
        this.$refs.boxxleft.style.width = `0%`;
        this.$refs.boxxleft.style.height = `710px`;
        this.$refs.rightcase.style.backgroundImage = `url('${
          this.baseURL
        }api/fileinfo/image/GetOriImage?filename=${this.filename}&ocrSource=${
          this.ocrSource == 1 ? 1 : 2
        }')`;
        this.$refs.rightcase.style.backgroundRepeat = "no-repeat";

        for (let i of this.$refs.boxrightfont) {
          i.style.border = `none`;
        }
        // if (this.ispictureFileName) {
        //   // console.log(this.pictureFileName);
        //   this.$refs.rightcase.style.backgroundImage = `url('${this.pictureFileName}')`;
        // }
      } else {
        this.$refs.boxxleft.style.height = `750px`;
        this.$refs.boxxleft.style.width = `50%`;
        this.$refs.boxxright.style.width = `50%`;
        this.$refs.rightcase.style.backgroundImage = ``;

        for (let i of this.$refs.boxrightfont) {
          i.style.border = `1px solid red`;
        }
        // if (this.ispictureFileName) {
        //   // console.log(this.pictureFileName);
        //   this.$refs.leftcase.style.backgroundImage = `url('${this.pictureFileName}')`;
        // }
      }
      this.show = e;
    },
    // 左盒子右击弹窗
    leftbtn(e) {
      this.$refs.rightbox.style.display = "none";
      this.$refs.dropopen.style.display = `none`;
      this.windowindex = -1;
      console.log(e.clientX, e.clientY);
      let btnbox = this.$refs.boxx;
      let disX = e.clientX - btnbox.offsetLeft;
      let disY = e.clientY - btnbox.offsetTop;
      // console.log(disX, disY);
      let leftbox = this.$refs.lefttbox;
      // console.log(leftbox);
      if (disY > 400) {
        leftbox.style.top = disY - 50 + "px";
        leftbox.style.display = "flex";
        leftbox.style.left = disX + "px";
      } else {
        leftbox.style.display = "flex";
        leftbox.style.left = disX + "px";
        leftbox.style.top = disY + "px";
      }
    },
    leftmenu() {
      this.windowindex = -1;
      this.$refs.lefttbox.style.display = "none";
      this.$refs.rightbox.style.display = "none";
      this.$refs.dropopen.style.display = `none`;
      this.$refs.rightfontbox.style.display = `none`;
    },
    rightmenu() {
      console.log("hh");
      this.windowindex = -1;
      console.log(this.windowindex);
      this.$refs.lefttbox.style.display = "none";
      this.$refs.rightbox.style.display = "none";
      this.$refs.rightfontbox.style.display = `none`;
    },
    // 取消选框
    canceldrop() {
      console.log("取消选框");
      let dropopen = this.$refs.dropopen;
      dropopen.style.display = `none`;
      let dropbox = this.$refs.dropbox;
      dropbox.style.left = `-1px`;
      dropbox.style.top = 0;
      dropbox.style.width = 0;
      dropbox.style.height = 0;
      dropbox.style.zIndex = `-1`;
    },
    dropcancel() {
      let dropopen = this.$refs.dropopen;
      dropopen.style.display = `none`;
    },
    //组合union  拆解split
    combination(i) {
      this.selectedChars = this.MultiSelectFinegranularityLits.map((p) => {
        return {
          id: p._id,
          boxID: p.boxID,
          genealogyOCRInfoID: this.singlefrom.id,
          sourceID: this.singlefrom.sourceID,
          rcInImage: p.rcInImage,
          code: p.code,
        };
      });
      console.log(this.MultiSelectFinegranularityLits);
      // 获取矩形大小
      let rightdropbox = this.$refs.rightdropbox;

      this.$request({
        url: `/api/proofread/user-center/union-words?OcrSource=${this.singlefrom.ocrSource}`,
        method: "POST",
        data: {
          taskNo: this.taskNo,
          newWord: this.newWord,
          sourceID: this.singlefrom.sourceID,
          genealogyOCRInfoID: this.singlefrom.id,
          genealogyISBN: this.singlefrom.genealogyISBN,
          selectedChars: this.selectedChars,
          mode: i,
          FontSize: this.inputsize,
          rectangle: {
            x: rightdropbox.offsetLeft,
            y: rightdropbox.offsetTop,
            width: rightdropbox.offsetWidth,
            height: rightdropbox.offsetHeight,
          },
        },
      })
        .then((res) => {
          // console.log("res", res);
          this.closegroup();
          this.singledetail();
          this.clearfont();
          return this.$message.success("组合成功~");
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //拆解组合-保存
    savecombination() {
      this.selectedChars = this.MultiSelectFinegranularityLits.map((p) => {
        return {
          id: p._id,
          boxID: p.boxID,
          genealogyOCRInfoID: this.singlefrom.id,
          sourceID: this.singlefrom.sourceID,
          rcInImage: p.rcInImage,
          code: p.code,
        };
      });
      console.log(this.selectedChars);
      if (this.selectedChars.length > 1) {
        return this.$message.error("更新保存大小位置时，只能选择一个字！");
      } else {
        let rightdropbox = this.$refs.rightdropbox;
        this.selectedChars[0].rcInImage = `${rightdropbox.offsetLeft},${
          rightdropbox.offsetTop
        },${rightdropbox.offsetLeft + rightdropbox.offsetWidth},${
          rightdropbox.offsetTop + rightdropbox.offsetHeight
        }`;
        // console.log(rightdropbox.offsetLeft);
        // console.log(this.selectedChars[0].rcInImage.split(",")[0]);
        // console.log(this.selectedChars[0]);
        this.$request({
          url: `/api/proofread/user-center/update-word-size-location-asnyc`,
          method: "POST",
          data: {
            taskNo: this.taskNo,
            sourceID: this.singlefrom.sourceID,
            genealogyOCRInfoID: this.singlefrom.id,
            genealogyISBN: this.singlefrom.genealogyISBN,
            selectedChars: this.selectedChars[0],
          },
        })
          .then((res) => {
            // console.log("res", res);
            this.closegroup();
            this.singledetail();
            this.clearfont();
            return this.$message.success("组合成功~");
          })
          .catch((e) => {
            console.log("e", e);
          });
      }
    },
    //获取组合框输入的字
    plaintextbtn(e) {
      let dropbox = this.$refs.rightdropbox;
      console.log(e.target.innerHTML);
      this.newWord = e.target.innerHTML;
      this.$refs.group.style.fontSize =
        dropbox.offsetWidth / this.$refs.group.innerText.length + "px";
      //this.$refs.group.style.fontSize =
      //dropbox.offsetHeight / this.$refs.group.innerText.length + "px";
    },
    //拆字组合
    splitgroup() {
      this.splitgroupshow = true;
      console.log("拆解组合");
      this.$refs.group.style.display = `flex`; //拆字输入框
      this.$refs.groupbtn.style.display = `block`; //拆字按钮
      this.$refs.rightbox.style.display = "none";
      // this.$refs.group.style.alignItems = `center`;
      // ------------------------------------------
      let dropbox1 = this.$refs.dropbox1;

      //获取字体距离
      // console.log(this.MultiSelectFinegranularityLits, "已选字体");
      let splitwidth = this.MultiSelectFinegranularityLits.sort(function (
        a,
        b
      ) {
        return a.rcInImage.split(",")[0] - b.rcInImage.split(",")[0];
      });
      let widthnum =
        splitwidth[splitwidth.length - 1].rcInImage.split(",")[0] -
        splitwidth[0].rcInImage.split(",")[0];
      let widthleft = splitwidth[0].rcInImage.split(",")[0];
      console.log("宽", widthnum);
      console.log(
        widthleft,
        splitwidth[splitwidth.length - 1],
        "字体最小最大-宽"
      );
      //-----------------------------------
      let splitheight = this.MultiSelectFinegranularityLits.sort(function (
        a,
        b
      ) {
        return a.rcInImage.split(",")[1] - b.rcInImage.split(",")[1];
      });
      let heightnum =
        splitheight[splitheight.length - 1].rcInImage.split(",")[1] -
        splitheight[0].rcInImage.split(",")[1];
      console.log("高", heightnum);
      console.log(
        splitheight[0],
        splitheight[splitheight.length - 1],
        "字体最小最大-高"
      );
      //对左侧画框进行预设值
      this.ofdropy = this.b.y;
      this.dropy = this.ccy;
      //对应的右侧画框
      let rightdropbox = this.$refs.rightdropbox; //右侧盒子
      rightdropbox.style.width = `${widthnum + 100}px`;
      rightdropbox.style.height = `${heightnum + 100}px`;
      rightdropbox.style.position = `absolute`;
      rightdropbox.style.opacity = `1`;
      rightdropbox.style.zIndex = `100`;
      rightdropbox.style.left = widthleft + "px";
      rightdropbox.style.top = splitheight[0].rcInImage.split(",")[1] + "px";
      this.$refs.group.style.fontSize = dropbox1.offsetWidth + "px";
      this.$refs.group.style.fontSize = dropbox1.offsetHeight + "px";
      // rightdropbox.style.border = `3px dashed red`;

      // 对应的左侧画框
      let leftdropbox = this.$refs.dropbox; //左侧盒子
      leftdropbox.style.width = `${widthnum + 100}px`;
      leftdropbox.style.height = `${heightnum + 100}px`;
      leftdropbox.style.position = `absolute`;
      leftdropbox.style.zIndex = `100`;
      leftdropbox.style.left = widthleft + "px";
      leftdropbox.style.top = splitheight[0].rcInImage.split(",")[1] + "px";
      leftdropbox.style.border = `3px dashed red`;
    },
    // 取消拆解
    closegroup() {
      this.splitgroupshow = false;
      this.$refs.dropbox1.style.opacity = `0`;
      this.$refs.dropbox1.style.zIndex = `-1`;
      this.$refs.group.style.display = `none`;
      this.$refs.groupbtn.style.display = `none`;
      // 删除对应左侧画框
      let leftdropbox = this.$refs.dropbox;
      leftdropbox.style.width = ``;
      leftdropbox.style.height = ``;
      leftdropbox.style.zIndex = `-1`;
      //删除对应右侧画框
      let rightdropbox = this.$refs.rightdropbox;
      rightdropbox.style.width = ``;
      rightdropbox.style.height = ``;
      rightdropbox.style.opacity = `0`;

      rightdropbox.style.left = `0`;
      rightdropbox.style.top = `0`;

      this.$refs.group.innerHTML = "";
    },
    //获取字体大小
    inputbtn(e) {
      console.log(e);
      this.$refs.group.style.fontSize = `${e}px`;
    },
    //选中拆解组合框时的事件
    groupdown(e) {
      console.log(e);
      this.isdown = false;
    },
    //松开拆解组合框时的事件
    groupup(e) {
      // console.log(e);
      this.isdown = true;
    },
    //取消相似字列表
    cancelsimilar(index) {
      console.log("取消");
      this.windowindex = -1;
      console.log(this.windowindex);
    },
    //顺时针旋转
    rote() {
      console.log("顺时针旋转");
      this.m = this.m + this.n;
      console.log(this.m);
      let rotebox = this.$refs.leftcase;
      rotebox.style.transform = `rotate(${this.m}deg)`;
      let lefttbox = this.$refs.lefttbox;
      lefttbox.style.display = "none";
    },
    // 逆时针旋转
    lrote() {
      console.log("逆时针旋转");
      this.m = this.m - this.n;
      console.log(this.m);
      let rotebox = this.$refs.leftcase;
      rotebox.style.transform = `rotate(${this.m}deg)`;
      let lefttbox = this.$refs.lefttbox;
      lefttbox.style.display = "none";
    },
    //放大
    amplification(i, j) {
      console.log(i, j);
      this.windowindex = "";
      this.zoomclient = j;
      if (this.zoomscale < 0.6 + this.smallPic) {
        this.zoomscale = this.zoomscale + i;
        this.$refs.boxleft.style.transform = `scale(${this.zoomscale})`;
        this.$refs.boxright.style.transform = `scale(${this.zoomscale})`;
      }
    },
    //缩小
    narrow(i, j) {
      // console.log(this.zoomscale);
      this.windowindex = "";
      this.zoomclient = j;
      if (
        this.zoomscale > 0.2 ||
        this.$refs.leftcase.offsetWidth * this.zoomscale > 500
      ) {
        this.zoomscale = this.zoomscale - i;
        this.$refs.boxleft.style.transform = `scale(${this.zoomscale})`;
        this.$refs.boxright.style.transform = `scale(${this.zoomscale})`;
        this.$refs.boxleft.style.top =
          parseFloat(this.$refs.boxleft.style.top) === 0
            ? 0
            : parseFloat(this.$refs.boxleft.style.top) * this.zoomscale + "px";
        this.$refs.boxleft.style.left =
          parseFloat(this.$refs.boxleft.style.left) === 0
            ? 0
            : parseFloat(this.$refs.boxleft.style.left) * this.zoomscale + "px";

        this.$refs.boxright.style.top =
          parseFloat(this.$refs.boxright.style.top) === 0
            ? 0
            : parseFloat(this.$refs.boxright.style.top) * this.zoomscale + "px";
        this.$refs.boxright.style.left =
          parseFloat(this.$refs.boxright.style.left) === 0
            ? 0
            : parseFloat(this.$refs.boxright.style.left) * this.zoomscale +
              "px";
      }
    },
    // 置后
    Afterbtn() {
      //  console.log(this.fontindex)
      this.fontindex.style.zIndex = `1`;
      this.$refs.rightfontbox.style.display = `none`;
    },
    //置前
    Beforebtn() {
      this.fontindex.style.zIndex = `3`;
      this.$refs.rightfontbox.style.display = `none`;
    },
    //世系编辑
    editlineage() {
      this.activeName = "fourth";
      this.isautofocus = !this.isautofocus;
      this.Fineisautofocus = !this.Fineisautofocus;
      console.log("Fineisautofocus", this.Fineisautofocus);
      console.log("isautofocus", this.isautofocus);
      //细颗粒度标引数组清空
      this.MemberWordModelDto = [];

      this.codefontList = [];
      this.codefontFontList = [];
      this.FinegranularityLits = [];
      this.$refs.rightbox.style.display = `none`;
      let boxrightfont = this.$refs.boxrightfont;
      for (let q of boxrightfont) {
        q.style.background = ``;
      }
    },

    //清空字体
    clearfont() {
      this.codefontList = [];
      this.codefontFontList = [];
      this.FinegranularityLits = [];
      this.$refs.rightbox.style.display = `none`;
      let boxrightfont = this.$refs.boxrightfont;
      for (let q of boxrightfont) {
        q.style.background = ``;
      }
    },
    //单击字体列表其中一项

    codefontmouseup() {
      console.log("太气了");
      // this.isdown=true
    },
    codefont(e, code, id, x, y, item, index) {
      this.MoveState = "";
      // this.RrcInImage = item.rcInImage;
      this.charID = item._id;
      this.boxfontid = item._id;

      console.log(item);
      this.fontindex = this.$refs.boxrightfont[index];
      this.RrcInImageWidth = this.$refs.boxrightfont[index].offsetWidth;
      this.RrcInImageHeight = this.$refs.boxrightfont[index].offsetHeight;
      this.$refs.copybox.style.width =
        this.$refs.boxrightfont[index].offsetWidth + "px";
      this.$refs.copybox.style.height =
        this.$refs.boxrightfont[index].offsetHeight + "px";
      console.log(this.RrcInImageWidth, this.RrcInImageHeight);
      // console.log(e);
      var b = JSON.parse(JSON.stringify(item));

      if (this.isautofocus) {
        let a = this.codefontList.indexOf(id);
        if (a == -1) {
          this.codefontList.push(id);
          this.codefontFontList.push(code);
          this.rcInImageList.push(b.rcInImage);
          this.FinegranularityLits.push(item);
        } else {
          this.codefontList.splice(a, 1);
          this.codefontFontList.splice(a, 1);
          this.rcInImageList.splice(a, 1);
          this.FinegranularityLits.splice(a, 1);
        }
        // console.log(this.codefontFontList);
        this.MemberWordModelDto = this.rcInImageList;
        this.selectedFontList = this.codefontList;
      }
    },
    //清除选区
    clearchoose() {
      console.log("清除选区");
      this.$refs.rightbox.style.display = "none";
      let boxrightfont = this.$refs.boxrightfont;
      //世系编辑
      this.codefontList = [];
      this.codefontFontList = [];
      this.rcInImageList = [];
      //细颗粒度
      this.FinegranularityLits = [];
      // -----------------------
      for (let p of boxrightfont) {
        p.style.background = ``;
      }
    },
    //删除所选
    cleararea() {
      this.FontVisible = true;
      this.$refs.rightbox.style.display = "none";
    },
    // 右盒子右击弹窗
    rightbtn(e) {
      // console.log(e);
      this.windowindex = -1;
      this.$refs.lefttbox.style.display = "none";
      this.$refs.rightfontbox.style.display = "none";
      let btnbox = this.$refs.boxx;
      let disX = e.clientX - btnbox.offsetLeft;
      let disY = e.clientY - btnbox.offsetTop;
      // console.log(disX, disY);
      let rightbox = this.$refs.rightbox;
      // console.log(rightbox);
      if (disY > 390) {
        rightbox.style.top = disY - 350 + "px";
        rightbox.style.display = "flex";
        rightbox.style.left = disX + "px";
      } else {
        rightbox.style.display = "flex";
        rightbox.style.left = disX + "px";
        rightbox.style.top = disY + "px";
      }
    },
    //右盒子字体右击弹窗
    rightfontbtn(e, item, index) {
      console.log(item);
      this.windowindex = -1;
      this.boxfontid = item._id;
      this.fontindex = this.$refs.boxrightfont[index];

      this.$refs.rightbox.style.display = "none";
      let btnbox = this.$refs.boxx;
      let disX = e.clientX - btnbox.offsetLeft;
      let disY = e.clientY - btnbox.offsetTop;
      console.log(disY);
      // console.log(disX, disY);
      let rightbox = this.$refs.rightfontbox;
      // console.log(rightbox);
      if (disY > 390) {
        rightbox.style.top = disY - 100 + "px";
        rightbox.style.display = "flex";
        rightbox.style.left = disX + "px";
      } else {
        rightbox.style.display = "flex";
        rightbox.style.left = disX + "px";
        rightbox.style.top = disY + "px";
      }
    },
    //族谱成员世系图
    MembersLineageImg() {
      console.log(this.taskNo, "族谱成员世系图");
      this.$request({
        url: `/api/knowledge/member`,
        method: "GET",
        params: {
          PageSize: 20,
          PageIndex: 1,
          SourceID: this.singlefrom.sourceID,
          GenealogyOCRInfoID: this.singlefrom.id,
          IsSearchRoot: false,
          InsertAncestor: false,
          TaskNo: this.taskNo,
        },
      })
        .then((res) => {
          console.log("族谱成员世系图", res);
          this.MembersFamilyList = res.items;
          //树形结构拼接
          // this.MemberList(res.items, "unknown", []);

          const filterUndefined = (item) => typeof item !== "undefined";

          this.tagslist = this.MembersFamilyList.map((p) => {
            if (p.sign == 1) {
              return p;
            }
          }).filter(filterUndefined);

          this.Norelationships = this.MembersFamilyList.map((p) => {
            if (p.relationship == null) {
              return p;
            }
          }).filter(filterUndefined);
          // console.log("无关系成员列表", this.Norelationships);
        })
        .catch((e) => {
          //return this.$message.error("服务器异常~");
        });
    },
    //树形结构拼接
    MemberList(rootlist, id, list) {
      // console.log(rootlist);
      for (let item of rootlist) {
        if (item.fatherID == id) {
          list.push(item);
          // console.log(list)
        }
      }
      for (let i of list) {
        i.children = [];
        this.MemberList(rootlist, i.id, i.children);
        if (i.children.length == 0) {
          delete i.children;
        }
      }
      console.log(list, "树形结构拼接后");
      // this.MembersFamilyList = list;
      // return list;
    },
    //族谱成员画线
    calculate(ref, fatherID, y, x) {
      // console.log(ref, fatherID);
      //竖线
      var verticalline = document.getElementById(ref + "b");
      //横线
      var acrossline = document.getElementById(ref + "c");

      for (let i of this.MembersFamilyList) {
        //查找子级父级相同的数据
        if (fatherID == i.id) {
          // console.log(acrossline);
          // console.log(i.fullName.split(''))
          // console.log(i.locations[0].rectangle.y, y);
          // console.log(i.locations[0].rectangle.x, x);
          if (verticalline || acrossline) {
            // console.log(linebox.style);
            verticalline.style.height =
              y - i.locations[0].rectangle.y - 180 + "px";
            verticalline.style.top =
              -(y - i.locations[0].rectangle.y) +
              i.fullName.split("").length * 100 +
              "px";

            if (i.locations[0].rectangle.x < x) {
              acrossline.style.width = x - i.locations[0].rectangle.x + "px";
              acrossline.style.left =
                -(x - i.locations[0].rectangle.x) + 50 + "px";
              acrossline.style.bottom = y - i.locations[0].rectangle.y + "px";
            }
            if (i.locations[0].rectangle.x > x) {
              // console.log(i.locations[0].rectangle.x - x, "横线长度");
              acrossline.style.width = i.locations[0].rectangle.x - x + "px";
              acrossline.style.left = 50 + "px";
              acrossline.style.bottom = y - i.locations[0].rectangle.y + "px";
            }
          }
        }
      }
    },
    handleCommand(e) {
      this.Innername = e;
      console.log(this.codefontFontList);
      this.Innerhtml = this.codefontFontList;
      this.$refs.editFileFill.begin();
    },
    closer(e) {
      this.nameParticleVisible = e;
    },
    Texthtml(e) {
      // -0----------------------------
      this.selectId = this.membersmsg.id;
      this.selectFullName = this.membersmsg.fullName;
      console.log(this.selectId, this.selectFullName);
      this.$refs.editAncestor.fillmsg(this.membersmsg.id);
    },
    //删除所选字
    DelFontVisible() {
      this.FontVisible = false;
      this.$request({
        url: "/api/proofread/user-center/batch-remove-words",
        method: "POST",
        data: {
          taskNo: this.taskNo,
          ocrInfoID: this.singlefrom.id,
          chardIds: this.selectedFontList,
        },
      })
        .then((res) => {
          // console.log("res", res);
          this.singledetail(this.taskNo);
          this.selectedFontList = [];
          // return this.$message.success("删除成功~");
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //右击画框、
    dropclick(e) {
      // console.log(e);
      this.$refs.lefttbox.style.display = "none";
      let rightbox = this.$refs.dropopen;
      rightbox.style.top = e.offsetY + "px";
      rightbox.style.left = e.offsetX + "px";
      rightbox.style.display = "flex";
    },
    rightbutton(val, e) {},
    //获得焦点
    focus(val) {
      //   console.log(val);
      this.dfocus = true;
    },
    focus1(val) {
      //   console.log(val);
      this.dfocus1 = true;
    },
    //失去焦点
    blur(val) {
      //   console.log(val);
      this.dfocus = false;
    },
    blur1(val) {
      //   console.log(val);
      this.dfocus1 = false;
    },
    // 从左往右
    rdl(e) {
      this.rd = e;
    },
    //从右到左
    rdr(e) {
      this.rd = e;
    },

    //刷新
    refresh() {
      this.loading = true;
      this.singledetail(this.taskNo);
      // this.MembersLineageImg();
      setTimeout((p) => {
        this.loading = false;
      }, 500);
      this.$refs.rightbox.style.display = `none`;
    },
    //族谱世系成员切换
    MembersFamilybtn() {
      this.MembersFamily = !this.MembersFamily;
      this.$refs.rightbox.style.display = `none`;
    },
    //弹窗生成段落
    showgraph() {
      this.graphVisible = true;
      this.$refs.rightbox.style.display = `none`;
    },
    // 拖拽
    down(e) {
      console.log(e.clientX, e.clientY, "++++++++++++++++++++++");
      // document.onselectstart = function () {
      //   return false;
      // }; //解决拖动会选中文字的问题
      //  ------------------------------
      let that = this;
      //外层盒子宽度
      let boxxright = that.$refs.boxxright;
      console.log(boxxright.offsetWidth, "boxxright");
      let outwidth = boxxright.offsetWidth;
      let outheight = boxxright.offsetHeight;
      console.log("外层盒子宽度", boxxright.offsetWidth);

      //内层盒子宽度
      let leftdiv = that.$refs.boxleft;
      let rightdiv = that.$refs.boxright;
      let innerwidth = that.pagex * that.zoomscale;
      let innerheight = that.pagey * that.zoomscale;
      // console.log("内层盒子宽度", this.pagex * 0.310719);
      // console.log("内层-外层", innerheight - outheight);
      console.log(innerwidth, "innerwidth");
      that.ZoomInOnLeft = innerwidth - outwidth;
      that.ZoomInOnTop = innerheight - outheight;

      //算出鼠标相对元素的位置

      // console.log(leftdiv.offsetLeft, leftdiv.offsetTop);
      var leftX = e.clientX;
      var topY = e.clientY;

      document.onmousemove = (e) => {
        //鼠标按下并移动的事件
        //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        if (that.isdown) {
          if (that.radio == 1) {
            that.$refs.rightcase.style.pointerEvents = `none`;
            that.$refs.leftcase.style.pointerEvents = `none`;
          }

          let left = e.clientX - leftX;
          // console.log(left, 'left')
          // console.log(that.distanceX + left);

          let top = e.clientY - topY;
          // console.log(that.distanceX + left, "  that.distanceX + left");
          // console.log(that.ZoomInOnLeft, "that.ZoomInOnLeft");

          if (
            that.distanceX + left <= 0 &&
            Math.abs(that.distanceX + left) <= that.ZoomInOnLeft
          ) {
            leftdiv.style.left = that.distanceX + left + "px";
            rightdiv.style.left = that.distanceX + left + "px";
          }
          if (
            that.distanceY + top <= 0 &&
            Math.abs(that.distanceY + top) <= that.ZoomInOnTop
          ) {
            leftdiv.style.top = that.distanceY + top + "px";
            rightdiv.style.top = that.distanceY + top + "px";
          }
        }
      };
      leftdiv.onscroll = (e) => {
        // console.log(e);
      };
      document.onmouseup = (e) => {
        if (that.radio == 1) {
          that.$refs.rightcase.style.pointerEvents = `auto`;
          that.$refs.leftcase.style.pointerEvents = `auto`;
        }

        // document.onselectstart = null;

        that.distanceX = leftdiv.offsetLeft;
        that.distanceY = leftdiv.offsetTop;
        console.log(leftdiv.offsetLeft, "leftdiv.offsetLeft;");
        console.log(leftdiv.offsetTop, "leftdiv.offsetTop;");

        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    // 画框
    moveleft() {
      // 点击
      let that = this;
      let aboutdiv = that.$refs.boxleft;
      aboutdiv.addEventListener("mousedown", (e) => {
        // 移动
        if (e.ctrlKey) {
          that.splitgroupshow = false;
          that.MoveState = "滑动中";
          // console.log(e.offsetX, e.offsetY);
          // console.log(aboutdiv);

          that.dropboxxnum.push(1);
          that.isdown = false;
          that.CutBoxmove = false;
          //获取鼠标开始拖动的起始位置
          let a = {};
          a.x = e.offsetX;
          a.y = e.offsetY;
          // console.log(a);

          let startPos = {};
          startPos.x = e.clientX;
          startPos.y = e.clientY;

          that.dropx = e.clientX;
          that.dropy = e.clientY;

          // console.log(that.dropx, that.dropy);

          that.ofdropx = e.offsetX;
          that.ofdropy = e.offsetY;
          // console.log(that.ofdropx, that.ofdropy, "左侧");
          function move(e) {
            // console.log(e)
            let curPos = {};
            curPos.x = e.clientX;
            curPos.y = e.clientY;
            // console.log(curPos);
            let dropbox = that.$refs.dropbox;
            dropbox.style.width =
              e.clientX / that.zoomscale - startPos.x / that.zoomscale + "px";
            dropbox.style.height =
              e.clientY / that.zoomscale - startPos.y / that.zoomscale + "px";
            dropbox.style.left = a.x + "px";
            dropbox.style.top = a.y + "px";
            dropbox.style.border = `2px dashed red`;
            dropbox.style.position = `absolute`;
            dropbox.style.zIndex = `100`;

            // aboutdiv.appendChild(dropbox);
          }
          //鼠标按下移动时动态获取鼠标位置
          aboutdiv.addEventListener("mousemove", move);

          //鼠标放下时，停止生成画框
          aboutdiv.addEventListener(
            "mouseup",
            function () {
              that.isdown = true;
              let Charlist = [];
              let dropbox = that.$refs.dropbox;

              let Maxx = dropbox.offsetLeft + dropbox.offsetWidth;
              let Maxy = dropbox.offsetTop + dropbox.offsetHeight;
              for (let p of that.proofreadList) {
                if (
                  p.rcInImage.split(",")[0] > dropbox.offsetLeft &&
                  p.rcInImage.split(",")[0] < Maxx &&
                  p.rcInImage.split(",")[1] > dropbox.offsetTop &&
                  p.rcInImage.split(",")[1] < Maxy - 40
                ) {
                  Charlist.push(p);

                  // console.log(that.FinegranularityLits);
                }
              }

              if (Charlist.length > 0) {
                //重新生成段落
                that
                  .$request({
                    url: "/api/proofread/user-center/build-paragraph-content",
                    method: "POST",
                    data: {
                      sourceID: that.singlefrom.sourceID,
                      ocrInfoID: that.singlefrom.id,
                      fdCharDtos: [],
                      holdSpace: true,
                      autoWriteModel: false,
                      landscape: false,
                    },
                  })
                  .then((res) => {
                    console.log("res", res);
                    //that.codefontFontList = [...new Set(res.content)];
                    that.codefontFontList = res.content;
                    console.log(that.codefontFontList);
                  })
                  .catch((e) => {
                    console.log("e", e);
                  });
              }

              that.FinegranularityLits = [...new Set(Charlist)];
              console.log("鼠标抬起了");
              aboutdiv.removeEventListener("mousemove", move);
            },
            {
              once: true,
            }
          );
        }
      });
    },
    ConfirmCutBox() {
      let dropbox = this.$refs.dropbox;
      dropbox.style.border = `2px solid red`;
      this.CutBoxmove = true;
      this.CutBoxVisible = false;
    },
    RConfirmCutBox() {
      console.log("切框右击了");
    },
    // 左侧盒子拖拽
    dropboxx(e) {
      // console.log(e);
      let that = this;
      that.isdown = false;
      let dropbox = that.$refs.dropbox;
      let aboutdiv = that.$refs.boxleft;
      that.dropboxX = dropbox.offsetLeft;
      that.dropboxY = dropbox.offsetTop;

      let drop = {};
      drop.x = e.clientX;
      drop.y = e.clientY;

      function move(e) {
        // console.log("拖拽");
        if (that.CutBoxmove == false) {
          if (that.a) {
            // console.log(e.clientX - drop.x);
            dropbox.style.left =
              e.clientX / that.zoomscale -
              drop.x / that.zoomscale +
              that.dropboxX +
              "px";
            dropbox.style.top =
              e.clientY / that.zoomscale -
              drop.y / that.zoomscale +
              that.dropboxY +
              "px";

            that.$refs.rightdropbox.style.left = dropbox.offsetLeft + "px";
            that.$refs.rightdropbox.style.top = dropbox.offsetTop + "px";
          }
        }

        // console.log(e);
      }
      //鼠标按下移动时动态获取鼠标位置
      aboutdiv.addEventListener("mousemove", move);
      aboutdiv.addEventListener(
        "mouseup",
        function () {
          // let list = [];
          let Charlist = [];
          that.isdown = true;
          let dropbox = that.$refs.dropbox;
          let Maxx = dropbox.offsetLeft + dropbox.offsetWidth;
          let Maxy = dropbox.offsetTop + dropbox.offsetHeight;
          for (let p of that.proofreadList) {
            if (
              p.rcInImage.split(",")[0] > dropbox.offsetLeft &&
              p.rcInImage.split(",")[0] < Maxx - 50 &&
              p.rcInImage.split(",")[1] > dropbox.offsetTop &&
              p.rcInImage.split(",")[1] < Maxy - 70
            ) {
              // list.push(p.code);
              Charlist.push(p);
            }
          }
          //重新生成段落
          that
            .$request({
              url: "/api/proofread/user-center/build-paragraph-content",
              method: "POST",
              data: {
                sourceID: that.singlefrom.sourceID,
                ocrInfoID: that.singlefrom.id,
                fdCharDtos: [], //Charlist,
                holdSpace: true,
                autoWriteModel: false,
                landscape: false,
              },
            })
            .then((res) => {
              console.log("res", res);
              //that.codefontFontList = [...new Set(res.content)];
              that.codefontFontList = res.content;
              console.log(that.codefontFontList);
            })
            .catch((e) => {
              console.log("e", e);
            });
          that.FinegranularityLits = [...new Set(Charlist)];
          console.log("盒子拖拽完成");
          aboutdiv.removeEventListener("mousemove", move);
        },
        {
          once: true,
        }
      );
    },
    moveright() {
      // 点击
      let that = this;
      let aboutdiv = that.$refs.boxxright;
      that.codefontList = [];
      function move(e) {
        // aboutdiv.style.pointerEvents = "none";
        if (e.ctrlKey) {
          that.MoveState = "滑动中";
          that.isdown = false;
          that.splitgroupshow = true;
          let dropbox = that.$refs.dropbox1;
          //获取鼠标开始拖动的起始位置
          let a = {};
          a.x = e.offsetX;
          a.y = e.offsetY;

          that.b.x = e.offsetX;
          that.b.y = e.offsetY;
          console.log(that.b.x, "x轴", that.b.y, "Y轴", "右侧");
          // console.log(a)
          let startPos = {};
          startPos.x = e.clientX;
          startPos.y = e.clientY;
          that.ccx = e.clientX;
          that.ccy = e.clientY;
          // console.log(startPos);
          // 移动
          function move(e) {
            that.isautofocus = false;
            that.Fineisautofocus = false;

            let curPos = {};
            curPos.x = e.clientX;
            curPos.y = e.clientY;
            // ---------------------------
            // that.movex = e.clientX;
            // that.movey = e.clientY;
            // console.log(that.movex, "x轴", that.movey, "Y轴");
            // ---------------------------
            that.offsetPos.x =
              that.b.x +
              e.clientX / that.zoomscale -
              startPos.x / that.zoomscale;
            that.offsetPos.y =
              that.b.y +
              e.clientY / that.zoomscale -
              startPos.y / that.zoomscale;
            // console.log(that.offsetPos.x, "x轴", that.offsetPos.y, "Y轴");
            // -----------------------------

            dropbox.style.width =
              e.clientX / that.zoomscale - startPos.x / that.zoomscale + "px";
            dropbox.style.height =
              e.clientY / that.zoomscale - startPos.y / that.zoomscale + "px";
            dropbox.style.left = a.x + "px";
            dropbox.style.top = a.y + "px";
            dropbox.style.border = `3px dashed #89abe3`;
            dropbox.style.background = `rgb(120, 181, 207, 0.3)`;
            dropbox.style.position = `absolute`;
            dropbox.style.zIndex = `100`;
            dropbox.style.opacity = "1";

            // 动态添加选中的颜色
            that.addcolor();
          }
          //鼠标按下移动时动态获取鼠标位置
          aboutdiv.addEventListener("mousemove", move);
          //鼠标放下时，停止生成画框
          aboutdiv.addEventListener(
            "mouseup",
            function () {
              // 动态添加所选字
              that.addfont();

              that.isdown = true;
              dropbox.style.opacity = "0";
              dropbox.style.zIndex = `-1`;
              // document.onselectstart = null;
              // aboutdiv.style.pointerEvents = "initial";
              // console.log("鼠标抬起了");
              aboutdiv.removeEventListener("mousemove", move);
            },
            {
              once: true,
            }
          );
        }
      }
      aboutdiv.addEventListener("mousedown", move);
    },
    //防抖
    debounce(fn, delay) {
      let timer = null; //借助闭包
      return function () {
        if (timer) {
          clearTimeout(timer);
        }
        timer = setTimeout(fn, delay); // 简化写法
      };
    },
    // 圆点拖拽-右下角
    circleclick(e) {
      console.log("右下角");
      let that = this;
      that.a = false;
      // console.log(e);
      let dropbox = that.$refs.dropbox;
      let aboutdiv = that.$refs.boxleft;
      // dropbox.style.pointerEevents = `none`;
      let startPos = {};
      startPos.x = e.clientX;
      startPos.y = e.clientY;

      let dropwidth = dropbox.style.width.substring(
        0,
        dropbox.style.width.length - 2
      );
      let dropheight = dropbox.style.height.substring(
        0,
        dropbox.style.height.length - 2
      );

      console.log(parseInt(dropwidth));
      function move(a) {
        // console.log(e);

        dropbox.style.width =
          a.clientX / that.zoomscale -
          startPos.x / that.zoomscale +
          parseInt(dropwidth) +
          "px";
        dropbox.style.height =
          a.clientY / that.zoomscale -
          startPos.y / that.zoomscale +
          parseInt(dropheight) +
          "px";
        if (that.splitgroupshow) {
          that.$refs.rightdropbox.style.width = dropbox.offsetWidth + "px";
          that.$refs.rightdropbox.style.height = dropbox.offsetHeight + "px";
        }
      }
      //鼠标按下移动时动态获取鼠标位置

      aboutdiv.addEventListener("mousemove", move);

      //鼠标放下时，停止生成画框
      aboutdiv.addEventListener(
        "mouseup",
        function () {
          that.a = true;
          // let list = [];
          let Charlist = [];
          let dropbox = that.$refs.dropbox;
          let Maxx = dropbox.offsetLeft + dropbox.offsetWidth;
          let Maxy = dropbox.offsetTop + dropbox.offsetHeight;
          for (let p of that.proofreadList) {
            if (
              p.rcInImage.split(",")[0] > dropbox.offsetLeft &&
              p.rcInImage.split(",")[0] < Maxx - 50 &&
              p.rcInImage.split(",")[1] > dropbox.offsetTop &&
              p.rcInImage.split(",")[1] < Maxy - 70
            ) {
              console.log(p.code);
              // list.push(p.code);
              Charlist.push(p);
            }
          }
          //重新生成段落
          that
            .$request({
              url: "/api/proofread/user-center/build-paragraph-content",
              method: "POST",
              data: {
                sourceID: that.singlefrom.sourceID,
                ocrInfoID: that.singlefrom.id,
                fdCharDtos: [], // Charlist,
                holdSpace: true,
                autoWriteModel: false,
                landscape: false,
              },
            })
            .then((res) => {
              console.log("res", res);
              //that.codefontFontList = [...new Set(res.content)];
              that.codefontFontList = res.content;
              console.log(that.codefontFontList);
            })
            .catch((e) => {
              console.log("e", e);
            });
          that.FinegranularityLits = [...new Set(Charlist)];
          console.log("鼠标抬起了");
          aboutdiv.removeEventListener("mousemove", move);
        },
        {
          once: true,
        }
      );
    },
    //圆点拖拽-左上-左下-右上
    circleclickcomment(e, i) {
      // console.log(i);
      let that = this;
      that.a = false;
      let dropbox = that.$refs.dropbox;
      let aboutdiv = that.$refs.boxleft;
      let cirlebox = that.$refs[i];
      console.log(cirlebox);
      let startPos = {};
      startPos.x = e.clientX;
      startPos.y = e.clientY;

      let dropwidth = dropbox.style.width.substring(
        0,
        dropbox.style.width.length - 2
      );
      let dropheight = dropbox.style.height.substring(
        0,
        dropbox.style.height.length - 2
      );

      cirlebox.style.width = parseInt(dropwidth) + "px";
      cirlebox.style.height = parseInt(dropheight) + "px";

      function move(a) {
        // console.log(a);
        // 外层盒子
        if (i == "lefttopbox") {
          cirlebox.style.width =
            parseInt(dropwidth) +
            startPos.x / that.zoomscale -
            a.clientX / that.zoomscale +
            "px";
          cirlebox.style.height =
            parseInt(dropheight) +
            startPos.y / that.zoomscale -
            a.clientY / that.zoomscale +
            "px";
          dropbox.style.width = cirlebox.style.width;
          dropbox.style.height = cirlebox.style.height;
          dropbox.style.left = //that.ofdropx
            that.ofdropx + //左右侧统一
            a.clientX / that.zoomscale -
            that.dropx / that.zoomscale +
            "px";

          dropbox.style.top =
            that.ofdropy +
            a.clientY / that.zoomscale -
            that.dropy / that.zoomscale +
            "px";
          if (that.splitgroupshow) {
            that.$refs.rightdropbox.style.width = dropbox.offsetWidth + "px";
            that.$refs.rightdropbox.style.height = dropbox.offsetHeight + "px";
            that.$refs.rightdropbox.style.left = dropbox.offsetLeft + "px";
            that.$refs.rightdropbox.style.top = dropbox.offsetTop + "px";
          }
        }
        if (i == "leftbottombox") {
          cirlebox.style.width =
            parseInt(dropwidth) +
            startPos.x / that.zoomscale -
            a.clientX / that.zoomscale +
            "px";
          cirlebox.style.height =
            parseInt(dropheight) +
            a.clientY / that.zoomscale -
            startPos.y / that.zoomscale +
            "px";
          dropbox.style.width = cirlebox.style.width;
          dropbox.style.height = cirlebox.style.height;
          dropbox.style.left =
            that.ofdropx + //左右侧统一
            a.clientX / that.zoomscale -
            that.dropx / that.zoomscale +
            "px";
          if (that.splitgroupshow) {
            that.$refs.rightdropbox.style.width = dropbox.offsetWidth + "px";
            that.$refs.rightdropbox.style.height = dropbox.offsetHeight + "px";
            that.$refs.rightdropbox.style.left = dropbox.offsetLeft + "px";
            that.$refs.rightdropbox.style.top = dropbox.offsetTop + "px";
          }
        }
        if (i == "righttopbox") {
          cirlebox.style.width =
            parseInt(dropwidth) +
            a.clientX / that.zoomscale -
            startPos.x / that.zoomscale +
            "px";
          cirlebox.style.height =
            parseInt(dropheight) +
            startPos.y / that.zoomscale -
            a.clientY / that.zoomscale +
            "px";
          dropbox.style.width = cirlebox.style.width;
          dropbox.style.height = cirlebox.style.height;
          dropbox.style.top =
            that.ofdropy +
            a.clientY / that.zoomscale -
            that.dropy / that.zoomscale +
            "px";

          if (that.splitgroupshow) {
            that.$refs.rightdropbox.style.width = dropbox.offsetWidth + "px";
            that.$refs.rightdropbox.style.height = dropbox.offsetHeight + "px";
            that.$refs.rightdropbox.style.left = dropbox.offsetLeft + "px";
            that.$refs.rightdropbox.style.top = dropbox.offsetTop + "px";
          }
        }
      }
      //鼠标按下移动时动态获取鼠标位置
      aboutdiv.addEventListener("mousemove", move);
      //鼠标放下时，停止生成画框
      aboutdiv.addEventListener(
        "mouseup",
        function () {
          that.a = true;
          // let list = [];
          let Charlist = [];
          let dropbox = that.$refs.dropbox;
          let Maxx = dropbox.offsetLeft + dropbox.offsetWidth;
          let Maxy = dropbox.offsetTop + dropbox.offsetHeight;
          for (let p of that.proofreadList) {
            if (
              p.rcInImage.split(",")[0] > dropbox.offsetLeft &&
              p.rcInImage.split(",")[0] < Maxx - 50 &&
              p.rcInImage.split(",")[1] > dropbox.offsetTop &&
              p.rcInImage.split(",")[1] < Maxy - 70
            ) {
              console.log(p.code);
              // list.push(p.code);
              Charlist.push(p);
            }
          }

          //重新生成段落
          that
            .$request({
              url: "/api/proofread/user-center/build-paragraph-content",
              method: "POST",
              data: {
                sourceID: that.singlefrom.sourceID,
                ocrInfoID: that.singlefrom.id,
                fdCharDtos: [], // Charlist,
                holdSpace: true,
                autoWriteModel: false,
                landscape: false,
              },
            })
            .then((res) => {
              console.log("res", res);
              //that.codefontFontList = [...new Set(res.content)];
              that.codefontFontList = res.content;
              console.log(that.codefontFontList);
            })
            .catch((e) => {
              console.log("e", e);
            });
          that.FinegranularityLits = [...new Set(Charlist)];

          // ------------------------------------
          let cirlebox = that.$refs[i];
          cirlebox.style.width = 0;
          cirlebox.style.height = 0;
          console.log("鼠标抬起了");
          aboutdiv.removeEventListener("mousemove", move);
        },

        {
          once: true,
        }
      );
    },
    //拆解组合圆点拖拽左下角
    splitclickcomment(e, i) {
      // console.log(i);
      let that = this;
      that.a = false;
      that.isdown = false;
      let dropbox = that.$refs.rightdropbox;
      let aboutdiv = that.$refs.boxright;

      let cirlebox = that.$refs[i];
      console.log(cirlebox);

      let startPos = {};
      startPos.x = e.clientX;
      startPos.y = e.clientY;
      let dropwidth = dropbox.offsetWidth;
      let dropheight = dropbox.offsetHeight;
      cirlebox.style.width = parseInt(dropbox.offsetWidth) + "px";
      cirlebox.style.height = parseInt(dropbox.offsetHeight) + "px";
      // cirlebox.style.border = `1px solid black`;
      console.log(cirlebox);

      function move(a) {
        // console.log(e);
        // 外层盒子
        if (i == "leftbottombox") {
          cirlebox.style.width =
            parseInt(dropwidth) +
            startPos.x / that.zoomscale -
            a.clientX / that.zoomscale +
            "px";
          cirlebox.style.height =
            parseInt(dropheight) +
            a.clientY / that.zoomscale -
            startPos.y / that.zoomscale +
            "px";
          dropbox.style.width = cirlebox.offsetWidth + "px";
          dropbox.style.height = cirlebox.offsetHeight + "px";
          dropbox.style.left =
            that.b.x + //左右侧统一
            a.clientX / that.zoomscale -
            that.ccx / that.zoomscale +
            "px";
        }
        if (i == "lefttopbox") {
          cirlebox.style.width =
            parseInt(dropwidth) +
            startPos.x / that.zoomscale -
            a.clientX / that.zoomscale +
            "px";
          cirlebox.style.height =
            parseInt(dropheight) +
            startPos.y / that.zoomscale -
            a.clientY / that.zoomscale +
            "px";
          dropbox.style.width = cirlebox.style.width;
          dropbox.style.height = cirlebox.style.height;
          dropbox.style.left =
            that.b.x + //左右侧统一
            a.clientX / that.zoomscale -
            that.ccx / that.zoomscale +
            "px";
          dropbox.style.top =
            that.b.y +
            a.clientY / that.zoomscale -
            that.ccy / that.zoomscale +
            "px";
        }
        if (i == "righttopbox") {
          cirlebox.style.width =
            parseInt(dropwidth) +
            a.clientX / that.zoomscale -
            startPos.x / that.zoomscale +
            "px";
          cirlebox.style.height =
            parseInt(dropheight) +
            startPos.y / that.zoomscale -
            a.clientY / that.zoomscale +
            "px";
          dropbox.style.width = cirlebox.style.width;
          dropbox.style.height = cirlebox.style.height;
          dropbox.style.top =
            that.b.y +
            a.clientY / that.zoomscale -
            that.ccy / that.zoomscale +
            "px";
        }
        if (i == "rightbottombox") {
          cirlebox.style.width =
            a.clientX / that.zoomscale -
            startPos.x / that.zoomscale +
            parseInt(dropwidth) +
            "px";
          cirlebox.style.height =
            a.clientY / that.zoomscale -
            startPos.y / that.zoomscale +
            parseInt(dropheight) +
            "px";
          dropbox.style.width = cirlebox.style.width;
          dropbox.style.height = cirlebox.style.height;
        }

        that.$refs.dropbox.style.width = dropbox.offsetWidth + "px";
        that.$refs.dropbox.style.height = dropbox.offsetHeight + "px";
        that.$refs.dropbox.style.left = dropbox.offsetLeft + "px";
        that.$refs.dropbox.style.top = dropbox.offsetTop + "px";

        // console.log(a.clientX, "clientx");
        if (dropbox.offsetWidth < dropbox.offsetHeight) {
          //that.$refs.group.style.alignItems = ``;
          that.$refs.group.style.fontSize =
            dropbox.offsetWidth / that.$refs.group.innerText.length + "px";
        } else {
          that.$refs.group.style.fontSize =
            dropbox.offsetHeight / that.$refs.group.innerText.length + "px";
          //that.$refs.group.style.alignItems = `center`;
        }
        console.log(that.$refs.group.innerText.length);
      }
      //鼠标按下移动时动态获取鼠标位置
      aboutdiv.addEventListener("mousemove", move);
      //鼠标放下时，停止生成画框
      aboutdiv.addEventListener(
        "mouseup",
        function () {
          that.a = true;
          that.isdown = true;

          let cirlebox = that.$refs[i];

          //清空内层盒子
          cirlebox.style.width = 0;
          cirlebox.style.height = 0;
          console.log("鼠标抬起了");
          aboutdiv.removeEventListener("mousemove", move);
        },

        {
          once: true,
        }
      );
    },
    //msg更新的数据
    updatemsg(val) {
      this.activeName = val;
    },
    //打开造字窗口
    makefont() {
      this.wordVisible = true;
    },
    //关闭造字弹窗
    wordcloser(val) {
      console.log(val);
      this.wordVisible = false;
      this.graphVisible = false;
    },
    // 单页校对详情
    singledetail(id) {
      console.log(id, "taskno");
      this.$request({
        url: `/api/proofread/user-center/${
          this.detailid
        }/document-oCRInfo?taskNo=${id ? id : ""}`,
        method: "GET",
        headers: {
          accept: "text/plain",
        },
        params: {
          PageSize: this.PageSize,
          PageIndex: this.PageIndex,
        },
      })
        .then((res) => {
          // 更改当前地址参数
          this.$router.push({
            path: "/proofreading/singlePageDetail",
            query: {
              id: res.id,
              sourceID: res.sourceID,
              index: 0,
              ocrSource: res.ocrSource,
            },
          });
          this.content = res.content;
          this.singlefrom = res;
          this.singleInfos = res.id;
          this.proofreadInfos(res.id);
          this.singcontent = res.content.split("").map((p, i) => {
            return {
              code: p,
              id: i,
            };
          });
          if (res.documentListDto.documentType === 0)
            res.documentListDto.documentType =
              res.documentListDto.documentTypeText;
          // this.documentListDto = res.documentListDto;
          this.documentListDto = res.returnDocumentDto;
          this.docId = res.documentListDto.id;
          this.processingFields(this.documentListDto.documentType);
          this.documentType = res.documentListDto.documentType;
          this.GeneralField = res.documentListDto;
          this.proofreadList = res.chars;
          this.filenameSize = res.boxs[0].rcInImage;
          this.ocrSource = res.ocrSource;

          console.log(
            res.pictureFileName.substring(res.pictureFileName.length - 3)
          );
          // if (
          //   res.pictureFileName.substring(res.pictureFileName.length - 3) ==
          //   "tif"
          // ) {
          //   this.ispictureFileName = true;
          //   this.Tifimg(
          //     `api/fileinfo/image/GetOriImage?filename=${res.pictureFullName}`
          //   );
          // } else {
          //   this.ispictureFileName = false;
          //   this.filename = res.pictureFullName;
          // }
          this.filename = res.pictureFullName;
          // this.CopyconvertRect("禾");
          //获取族谱成员世系图
          if (this.taskNo.substring(0, 2) == "MC" || this.taskNo == "") {
            // this.MembersLineageImg();
          }
          //获取集字校对的数据对单页进行选框--------------
          if (this.$route.query.charID) {
            let pos = this.proofreadList
              .map(function (p) {
                return p._id;
              })
              .indexOf(this.$route.query.charID);
            console.log(pos, "posIndex");
            // this.hoverIndex = pos;
            this.windowindex = this.$route.query.charID;
            // -----------------------------
            let dropbox = this.$refs.dropbox;
            console.log(this.convertRect(this.proofreadList[pos].rcInImage)[0]);
            dropbox.style.width =
              this.convertRect(this.proofreadList[pos].rcInImage)[0].width +
              "px";
            dropbox.style.height =
              this.convertRect(this.proofreadList[pos].rcInImage)[0].height +
              "px";
            dropbox.style.left =
              this.convertRect(this.proofreadList[pos].rcInImage)[0].x + "px";
            dropbox.style.top =
              this.convertRect(this.proofreadList[pos].rcInImage)[0].y + "px";
            dropbox.style.border = `2px dashed red`;
            dropbox.style.position = `absolute`;
            dropbox.style.zIndex = `100`;
          }

          // 解决双层点击缩略图背景图不换的bug 和隐藏背景翻页后没效果
          if (this.radio === "2" && !this.hiddenback) {
            // 图片
            this.$refs.rightcase.style.backgroundImage = `url('${
              this.baseURL
            }api/fileinfo/image/GetOriImage?filename=${
              this.filename
            }&ocrSource=${this.ocrSource == 1 ? 1 : 2}')`;
          }
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    // 处理文献的特殊字段
    processingFields(docmuentType) {
      switch (docmuentType) {
        // 古籍
        case 134:
          this.extractValue(this.$store.state.ancientWorks);
          break;
        // 谱牒
        case 133:
          this.extractValue(this.$store.state.genealogy);
          break;
        // 方志
        case 131:
          console.log("------------------");
          this.extractValue(this.$store.state.localRecords);
          break;
        // 历史档案
        case 132:
          this.extractValue(this.$store.state.historicalArchives);
          break;
        // 图书
        case 100:
          break;
      }
    },
    // 提取并设置特殊字段的值
    extractValue(list) {
      list.forEach((v) => {
        console.log(
          this.documentListDto[v.label],
          "this.documentListDto[v.label]"
        );
        v[v.label] = this.documentListDto[v.label];
      });
      console.log(list, "list");
      console.log(
        this.$store.state.ancientWorks,
        "this.$store.state.ancientWorks"
      );
    },
    //获取文献数量、校对完成数量，归入章节等
    proofreadInfos(id) {
      // this.$request({
      //     url: `/api/proofread/user-center/${id}/proofread-infos`,
      //     method: "GET",
      // })
      //     .then((res) => {
      //         console.log("校对完成数量", res);
      //         this.documentTitles = res;
      //     })
      //     .catch((e) => {
      //         console.log("e", e);
      //     });
    },
    //获取章节列表-查询根目录
    getChapter(url, SourceID, Keyword) {
      console.log("SourceID", SourceID);
      console.log(this.taskNo, "获取章节-查询根目录");
      this.$request({
        url,
        method: "GET",
        params: {
          IsNoPage: true,
          PageSize: 20,
          PageIndex: 1,
          SourceID,
          ParentId: "00000000-0000-0000-0000-000000000000",
          IsSearchRoot: true,
          TaskNo: this.taskNo,
        },
      })
        .then((res) => {
          this.getChapterList = res.items;
          console.log("getChapterListxxxx", res.items);
        })
        .catch((e) => {
          //return this.$message.error("服务器异常~");
        });
    },

    //提交-自定义更改字体
    submitfont(code, id, rcInImage) {
      console.log(code, id, rcInImage);
      console.log("点击了提交");
      if (this.unfold) {
        this.smilfont(this.unfold, id, rcInImage);
      } else {
        return this.$message.error("请输入内容~");
      }
    },
    // 单击字体列表
    fontlistbtn(e, index, item) {
      console.log(e, index, item, "item");
      let rightdiv = this.$refs.boxright;
      this.$refs.rightbox.style.display = `none`;
      this.$refs.rightfontbox.style.display = `none`;
      console.log(rightdiv.offsetLeft, rightdiv.offsetTop);
      if (!this.Fineisautofocus && !this.isautofocus) {
        this.windowindex = item._id;
        this.$nextTick(() => {
          this.$refs.saveTagInput.focus();
        });
        this.fontlist = item;
        var d1 = document.querySelector("#fontwindow");
        let btnbox = this.$refs.boxx;
        let disX = e.clientX - btnbox.offsetLeft;
        let disY = e.clientY - btnbox.offsetTop;
        if (disY > 450) {
          d1.style.left = disX + "px";
          d1.style.top = disY - 250 + "px";
        } else {
          d1.style.left = disX + "px";
          d1.style.top = disY + "px";
        }

        // console.log(disX, "字体列表坐标");
        console.log(item.similar);
        if (!Array.isArray(item.similar) && item.similar != null) {
          this.fontlist.similar = item.similar.split(",");
        }
      }
    },
    // 更改相似字
    smilfont(val, id, rcInImage) {
      this.$request({
        url: "/api/proofread/user-center/char-info",
        method: "PUT",
        data: {
          id: id,
          code: val,
          rcInImage: rcInImage,
          sourceID: this.sourceID,
          ocrInfoID: this.detailid,
          taskNo: this.taskNo,
        },
      })
        .then((res) => {
          console.log(res);
          this.singledetail(this.taskNo);
          this.windowindex = -1;
          this.unfold = "";
          //return this.$message.success("修改成功~");
        })
        .catch((e) => {
          //return this.$message.error("服务器异常~");
        });
    },
    //获取造字编码
    changefont(i) {
      this.unfold = i;
      console.log(this.unfold);
    },
    //送入二次识别
    SecondRecognition() {
      this.SecondVisible = true;
    },
    Secondconfirm() {
      this.$request({
        url: "/api/discerntask/discern-task/create-task",
        method: "POST",
        data: {
          sourceID: this.sourceID,
          pictureFileName: this.singlefrom.pictureFileName,
          picturePath: this.singlefrom.picturePath,
          forceUpdate: true,
          isVertical: true,
          title: this.singlefrom.genealogyName,
        },
      })
        .then((res) => {
          this.SecondVisible = false;
          console.log("res", res);
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //细颗粒度
    FineParticleBtn(i) {
      // this.Fineisautofocus = true;
      console.log("Fineisautofocus", this.Fineisautofocus);
      console.log("isautofocus", this.isautofocus);
      // 世系编辑字体选项数组清空
      this.MemberWordModelDto = [];
      this.codefontList = [];
      // this.codefontFontList = [];
      let boxrightfont = this.$refs.boxrightfont;
      this.boxrightfontref = this.$refs.boxrightfont;
      for (let p of boxrightfont) {
        p.style.background = ``;
      }

      // -----------------------
      this.FineParticle = i;
      this.FineParticleName = true;
      this.activeName = "seven";
      this.$refs.rightbox.style.display = `none`;
    },
    //关闭细颗粒选字功能
    ClearFineParticleBtn() {
      this.$refs.rightbox.style.display = `none`;
      this.Fineisautofocus = false;
    },
    //审核通过
    AuditThrough() {
      this.$request({
        url: "/api/proofread/task/audit",
        method: "POST",
        data: {
          taskNo: this.taskNo,
          sourceID: this.sourceID,
          auditStatus: false,
          auditRemark: this.remark,
        },
      })
        .then((res) => {
          console.log("res", res);
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-footer {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 30px;
}

.card {
  box-sizing: border-box;
  width: 100%;
  height: 850px;
  border-bottom: 1px solid #ddd;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;

  .header {
    box-sizing: border-box;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #333;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;

    .btn {
      width: fit-content;
      background-color: #5cb85c;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      border-radius: 4px;
      user-select: none;
      cursor: pointer;
    }
  }

  .content {
    box-sizing: border-box;
    display: flex;
    width: 100%;

    .c-left {
      box-sizing: border-box;
      width: 80%;

      .header-button {
        width: 100%;
        // height: 100px;
        background-color: #f5f5f5;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 10px;
        padding-top: 10px;
      }

      .proofread {
        width: 100%;

        position: relative;
        background: #ddd;

        .box-left {
          position: absolute;
          left: 0px;
          top: 0;
          cursor: move;
          //background: steelblue;
          // transform: rotate(0deg);
          transition: 0.5s;
          user-select: none;
          background-size: 200% 100%;
          background-position: center center;
          background: rgba(231, 231, 231, 0.3);
        }

        .leftmask {
          position: absolute;
          left: 0px;
          top: 0;
          cursor: move;
        }

        .boxx-left {
          position: absolute;
          width: 50%;
          height: 750px;
          top: 0;
          left: 0;
          overflow-x: hidden;
          transition: 0.5s;
          background: rgb(255, 255, 255);
        }

        .box-right {
          position: absolute;

          top: 0;
          // z-index: 100;
          left: 0;
          cursor: move;
          // background: rgb(236, 138, 138);
          transition: 0.5s;
          user-select: none;
          // border: 10px solid lightblue;
        }

        .box-right-font {
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 60px;
          // user-select: none;
          position: absolute;
          color: #337ab7;
          // line-height: 68px;
          cursor: pointer;
          // pointer-events: none;
          // border: 1px solid red;
        }

        .box-right_font {
          display: flex;
          // align-items: center;
          justify-content: center;
          font-size: 60px;
          //  background: #66afe9;
        }

        .box-right-font-window {
          position: absolute;
          left: 0;
          z-index: 10;
        }

        .window-ctn {
          width: 160px;
          height: 230px;
          background: #337ab7;
          overflow-x: hidden;
        }

        .window-ctn-list {
          width: 160px;
          height: 230px;
          background: white;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          overflow: auto;
        }

        .window-list-box {
          width: 160px;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          cursor: pointer;
          color: #337ab7;
        }

        .window-list-box:hover {
          background: #ddd;
          text-decoration: underline;
        }

        .hoverColor {
          user-select: none;
          // font-size: 60px;
          position: absolute;

          background: rgba(165, 163, 163, 1);
          font-family: KaiTi;
          // font-size: 68px;

          cursor: pointer;
          // text-decoration: underline;
        }

        .dropimglist {
          pointer-events: none;
          color: #337ab7;
          user-select: none;
          position: absolute;
          width: 60px;
          height: 60px;
          font-family: KaiTi;
          font-size: 68px;
          line-height: 68px;
          cursor: pointer;
          text-decoration: underline;
        }

        .dropimglist-font {
          color: #337ab7;
          font-size: 68px;
          font-family: KaiTi;
          cursor: pointer;
          text-decoration: underline;
        }

        .ImghoverColor {
          user-select: none;
          font-size: 60px;
          position: absolute;
          width: 60px;
          height: 60px;
          border: 5px solid red;
          font-family: KaiTi;
          font-size: 68px;
          line-height: 68px;
          cursor: pointer;
          text-decoration: underline;
        }

        .downColor {
          user-select: none;
          font-size: 60px;
          position: absolute;
          width: 60px;
          height: 60px;
          background: rgb(255, 0, 0);
          font-family: KaiTi;
          font-size: 68px;
          line-height: 68px;
          cursor: pointer;
          text-decoration: underline;
        }

        .caseColor {
          user-select: none;
          font-size: 60px;
          position: absolute;
          width: 60px;
          height: 60px;
          background: #f0ad4e;
          font-family: KaiTi;
          font-size: 68px;
          line-height: 68px;
          cursor: pointer;
          text-decoration: underline;
        }

        .FinecaseColor {
          user-select: none;
          font-size: 60px;
          position: absolute;
          width: 60px;
          height: 60px;
          background: #ce7f10;
          font-family: KaiTi;
          font-size: 68px;
          line-height: 68px;
          cursor: pointer;
          text-decoration: underline;
        }

        .slectbox {
          user-select: none;
          position: absolute;
          width: 60px;
          height: 60px;
          border: 2px solid red;
          display: block;
        }

        .boxx-right {
          position: absolute;
          width: 50%;
          height: 750px;
          top: 0;
          right: 0;
          overflow-x: hidden;
          overflow-y: scroll;
          transition: 0.5s;
          background: #ddd;
        }
      }
    }

    .c-right {
      box-sizing: border-box;
      width: 28%;
      border: 1px solid #ddd;

      .ul-list {
        display: flex;
        list-style: none;
        color: #333;
        border-bottom: 1px solid #ddd;

        .l-item {
          padding: 10px 15px;
          font-size: 14px;
          color: #337ab7;
        }

        .c-active {
          color: #555;
          cursor: default;
          background-color: #fff;
          border: 1px solid #ddd;
          border-radius: 4px 4px 0 0;
        }
      }

      .c-body {
        .title {
          padding: 10px 15px;
          background-color: #f5f5f5;
          border-bottom: 1px solid #ddd;
          color: #333;
          border-bottom: 1px solid #ddd;
        }

        .c-form {
          padding: 10px 15px;
          border-bottom: 1px solid #ddd;
        }

        .edit-btn {
          box-sizing: border-box;
          display: flex;
          justify-content: flex-end;
          padding: 10px 15px;
          background-color: #f5f5f5;

          button {
            width: fit-content;
            color: #fff;
            background-color: #337ab7;
            border-color: #2e6da4;
            border-radius: 4px;
            cursor: pointer;
            padding: 6px 12px;
            border: none;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      .relationship {
        overflow-y: auto;
        width: 100%;
        height: 225px;
      }
    }
  }
}

.pic {
  width: 100%;
  height: 50px;
  //   background: rgb(240, 239, 239);
  border: 1px solid rgb(240, 239, 239);
  display: flex;
  align-items: center;
}

.pic-ctn {
  width: 100%;
  height: 703px;
  background: rgb(247, 207, 180);
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.pic-ctn-bottom {
  display: flex;
  align-items: center;
  justify-content: center;

  bottom: 0;
  position: absolute;
  width: 100%;
  height: 60px;
  background: white;
  border-top: 1px solid #e9e8e8;
}

.pic-list {
  width: 100%;
  height: 900px;
  background: lightgoldenrodyellow;
}

.paragraph {
  width: 468px;
  height: 653px;
  writing-mode: tb-rl;
  font-size: 20px;
  padding: 10px;
  line-height: 33px;
  white-space: pre-wrap;
  overflow-x: scroll;
  overflow-y: scroll;
}

.paragraph1 {
  width: 468px;
  height: 653px;
  writing-mode: lr-tb;
  font-size: 20px;
  padding: 10px;
  line-height: 33px;
  white-space: pre-wrap;
  overflow-x: hidden;
  overflow-y: scroll;
}

.pic-list-box {
  cursor: pointer;
  margin-top: 20px;
  margin-left: 20px;
  width: 170px;
  height: 170px;
  border: 1px solid #ddd;
  border-radius: 3px;
  position: relative;
}

.pic-list-box-button {
  position: absolute;
  bottom: 8px;
  left: 5px;
}

.edit-title {
  width: 100%;
  height: 45px;
  background-color: #f5f5f5;
  color: #333;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.edit-input {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.members {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}

.members1 {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}

.members2 {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}

.members3 {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}

.members5 {
  width: 98%;
  // margin-left: 10px;
  height: 200px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.members6 {
  width: 98%;
  // margin-left: 10px;
  height: 380px;
  border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  position: relative;
}

.members5-list {
  width: 98%;
  margin-top: 10px;

  height: 300px;
  // border: 1px solid #ddd;
  border-radius: 4px;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
}

.members5-list-box {
  margin-left: 10px;
  width: 150px;
  height: 150px;

  border: 1px solid #ddd;
  border-radius: 5px;
}

.mulu-detail {
  width: 100%;
  display: flex;
  justify-content: right;

  padding: 15px;
}

// 右击盒子
.right-btn-box {
  font-size: 14px;
  width: 125px;
  // height: 100px;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  box-shadow: 2px 2px 5px #666;
  border: 1px solid #aab4b4;
  padding: 1px;
  z-index: 1;
}

.right-list {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 93%;
  flex: 1;
  color: black;
  cursor: pointer;
}

.right-list:hover {
  background: #337ab7;
  z-index: 99;
}

/* ---------------------------------- */
.left-btn-box {
  width: 120px;
  font-size: 14px;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  box-shadow: 2px 2px 5px #666;
  border: 1px solid #aab4b4;
  padding: 1px;
}

// --------------------------------------
// 树形右击盒子
// 右击盒子
.right-btn-box1 {
  width: 120px;
  height: 100px;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // display: none;
  box-shadow: 2px 2px 5px #666;
  border: 1px solid #aab4b4;
  padding: 1px;
}

.right-list1 {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  color: black;
  cursor: pointer;
}

.right-list1:hover {
  background: #337ab7;
  z-index: 99;
}

.dropwindow {
  width: 300px;
  // height: 350px;
  background: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;
  box-shadow: 2px 2px 5px #666;
  border: 1px solid #aab4b4;
  padding: 1px;
  transform: scale(1);
  padding: 15px;
}

.drop-list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  color: black;
  cursor: pointer;
  transform: scale(1);
  font-size: 40px;
}

.drop-list:hover {
  background: #337ab7;
  z-index: 99;
}

.lefttopbox {
  position: absolute;
  right: 0;
  bottom: 0;
  //background: #f0ad4e;
}

.leftbottombox {
  position: absolute;
  right: 0;
  top: 0;
  // background: #f0ad4e;
}

.rightttopbox {
  position: absolute;
  bottom: 0;
  left: 0;
  // background: #f0ad4e;
}

.rightbottombox {
  position: absolute;
  top: 0;
  left: 0;
}

.prompt {
  position: absolute;
  right: -55px;
  top: 0;
  text-align: center;
  line-height: 35px;
  font-size: 33px;
  padding: 5px;
  width: 40px;
  background: #787979;
  border-radius: 30px;
  color: white;
}

.dropcircle-rightbottom1 {
  position: absolute;
  right: -10px;
  bottom: -10px;
  background: #89abe3;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  cursor: se-resize;
}

.dropcircle-rightbottom {
  position: absolute;
  right: -20px;
  bottom: -10px;
  background: red;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  cursor: se-resize;
  z-index: 101;
  //display: none;
}

.dropcircle-righttop {
  position: absolute;
  right: -10px;
  top: -10px;
  background: red;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  cursor: sw-resize;
  z-index: 101;
  //display: none;
}

.dropcircle-lefttop {
  position: absolute;
  left: -10px;
  top: -10px;
  background: red;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  cursor: nw-resize;
  z-index: 101;
  //display: none;
}

.dropcircle-leftbottom {
  position: absolute;
  left: -10px;
  bottom: -10px;
  background: red;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  cursor: sw-resize;
  z-index: 101;
  // display: none;
}

.groupinput {
  // padding: 20px;
  word-break: break-all;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  font-size: 50px;
  // overflow-y: scroll;
  // overflow-x: hidden;
  border: 7px solid black;
  border-radius: 20px;
  display: none;
  z-index: 100;
  display: flex;
  //align-items: center;
}

.groupinput:focus {
  transition: border linear 0.1s, box-shadow linear 0.2s;
  // box-shadow: 0 0 10px #66afe9; //向外漫射程度1
  // -moz-box-shadow: 0 0 10px #66afe9; //向外漫射程度2
  // -webkit-box-shadow: 0 0 10px #66afe9; //向外漫射程度3
  border: 4px solid black;
  outline: 0;
}

.group-button {
  width: 70px;
  position: absolute;
  right: -176px;
  top: 210px;
  transform: scale(3);
  display: none;
}

.copybox {
  position: absolute;
  top: 0px;
  left: 0px;
  // width: 150px;
  // height: 150px;
  border: 3px solid red;
  z-index: 99;
  display: none;
}

.toopbtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 29.5px;
  background: #fff;
  border: 1px solid #dcdfe6;
  font-size: 15px;
  cursor: pointer;
}

.toopbtn1 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 29.5px;
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
  border: 1px solid #c6e2ff;
  font-size: 15px;
  cursor: pointer;
}

.toopbtn:hover {
  color: #409eff;
  border-color: #c6e2ff;
  background-color: #ecf5ff;
}

.MembersList::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: var(--bgColor);
}

.MembersList {
  position: relative;
}

.membersfont {
  font-family: "KaiTi";
}

@media screen and (max-width: 1750px) {
  .header-button {
    padding-bottom: 20px;
  }
}

/deep/ .el-card__body {
  padding: 0;
}

/deep/.el-button + .el-button {
  margin-left: 0;
}

/deep/.but .el-button {
  border-radius: 0;
}

.boxx-right::-webkit-scrollbar {
  width: 0 !important;
}

.boxx-left::-webkit-scrollbar {
  width: 0 !important;
}

/deep/.el-tabs__header {
  margin: 0px;
}

/deep/.pic .el-radio {
  margin-right: 0px;
}
</style>
