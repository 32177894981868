<template>
  <div>
    <el-dialog
      style=""
      top="20vh"
      title="造字"
      :close-on-click-modal="false"
      :visible.sync="DialogAddVisible"
      width="42%"
    >
      <!-- 输入框 -->
      <div class="inputbox">
        <div style="width: 50px">结构:</div>
        <el-select v-model="form.region" placeholder="">
          <el-option label="半包围" value="shanghai"></el-option>
          <el-option label="左右" value="beijing"></el-option>
          <el-option label="上下" value="beijing"></el-option>
        </el-select>
        <div style="width: 50px; margin-left: 5px">编码:</div>
        <div class="ucodebox">{{ `[${createNumber.join("")}]` }}</div>
        <div style="width: 50px; margin-left: 5px">输入:</div>
        <el-input
          style="width: 300px; margin-left: 15px"
          v-model="form.name"
          placeholder="输入待造字组成部分"
        ></el-input>
        <el-button
          style="margin-left: 10px"
          type="primary"
          size="mini"
          @click="createbox"
          >提交</el-button
        >
      </div>
      <!-- 造字 -->
      <div class="wordctn" ref="wordctn">
        <div class="wordctn-left" id="svgfont">
          <!-- ---------------------------- -->

          <!-- :style="{
                    transform: `scale(${scalex},${scaley})`,
                  }" -->
          <div class="wordctn-left-box" style="width: 600px; height: 375px">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="100%"
              viewBox="0 0 375 375"
            >
              <g
                v-for="(item, index) in createNumber"
                :key="item.id"
                @mousedown="wordmove($event, index + 1)"
              >
                <defs></defs>
                <text
                  :id="`${index + 1 + 'MakeWorda'}`"
                  transform="matrix(1,0,0,1,0,0)"
                  :x="svgx"
                  :y="svgy"
                  font-size="275"
                >
                  {{ item }}
                </text>
                <!-- svg推拽点 -->
                <path
                  fill="#fff"
                  :id="`${index + 1 + 'MakeWordb'}`"
                  @mousedown="dropmove($event, index + 1)"
                  :stroke="pathcolor"
                  d="M 363 362 L 373 362 373 372 363 372 363 362 Z"
                  style="cursor: default"
                  transform="matrix(1,0,0,1,-150,-23)"
                ></path>
              </g>
            </svg>
            <!-- 拖拽点 -->
            <!-- <div class="drop" @mousedown="dropdown($event, index + 1)"></div> -->
          </div>
        </div>
        <div class="wordctn-right">
          <div class="wordctn-right-title">
            <el-select
              style="margin-left: 20px"
              v-model="value"
              placeholder="请选择"
              @change="selectChanged"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span style="margin-left: 10px; font-size: 20px">划</span>
          </div>
          <!-- 笔画列表 -->
          <div class="stroke-list">
            <div style="margin-left: 15px; margin-top: 10px">
              <div
                class="strockbox"
                :style="{ background: strokeindex == index ? `#ddd` : '' }"
                v-for="(item, index) in strokefont"
                :key="item.id"
                @click="btnstroke(index)"
              >
                {{ item.partial }}
              </div>
            </div>
          </div>
          <div class="wordctn-right-bottom">
            <el-button
              style="margin-right: 20px"
              type="primary"
              size="mini"
              @click="application"
              >应用</el-button
            >
          </div>
        </div>
      </div>
      <!-- 展开按钮 -->
      <div class="showbtn" ref="showbbtn" @click="showbtn('block')">
        <i class="el-icon-d-arrow-right"></i>
      </div>
      <!-- 展开内容 -->
      <div class="showctn" ref="showctn">
        <div class="showbtn1" ref="showbbtn1" @click="showbtn('none')">
          <i class="el-icon-d-arrow-left"></i>
        </div>
        <!-- 标题 -->
        <div class="unfold-title">
          <el-input
            style="width: 500px; margin-left: 20px"
            v-model="unfoldkeyword"
            placeholder="请输入内容"
          ></el-input>
          <el-button
            style="margin-left: 20px"
            size="small"
            type="primary"
            @click="searchkeyword"
            >搜索</el-button
          >
        </div>
        <!-- 列表 -->
        <div class="unfold-list">
          <div
            :style="{
              backgroundImage: `url('${baseurl}api/proofread/WordBuilder/GetWordImage?uCode=${item.uCode}')`,
            }"
            :class="[
              choosecodeIndex == index ? 'unfoldListBox1' : 'unfoldListBox',
            ]"
            v-for="(item, index) in CustomList"
            :key="item.id"
            @click="choosecode(item, index)"
          >
            <div class="unfold-list-bottom">
              {{ item.code }}
            </div>
          </div>
          <el-button
            style="
              position: absolute;
              left: 50%;
              bottom: 75px;
              transform: translate(-60%);
            "
            type="primary"
            size="small"
            @click="loadunfold"
            >加载列表</el-button
          >
        </div>
        <!-- 底部 -->
        <div class="unfold-bottom">
          <el-button type="primary" size="small" @click="seleteWordClick"
            >选择</el-button
          >
          <el-button
            style="margin-left: 10px"
            type="primary"
            size="small"
            @click="DialogAddVisible = false"
            >关闭</el-button
          >
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" type="danger" size="small"
          >清空</el-button
        >
        <el-button
          style="margin-left: 10px"
          @click="detailword"
          type="danger"
          size="small"
          >删除</el-button
        >
        <el-button
          style="margin-left: 10px"
          @click="savefont"
          type="primary"
          size="small"
          >保存</el-button
        >
        <el-button
          style="margin-left: 10px"
          @click="dialogVisible = false"
          size="small"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: ["wordVisible"],
  data() {
    return {
      drawer: false,
      form: {
        name: "",
        region: "",
      },
      input: "",
      options: [
        {
          value: "1",
          label: "一划",
        },
        {
          value: "2",
          label: "二划",
        },
        {
          value: "3",
          label: "三划",
        },
      ],
      strokecount: 1,
      value: "",
      /* 分页 */
      PageIndex: 1,
      PageSize: 30,
      unfoldkeyword: "",
      // 偏旁列表
      strokefont: [],
      strokeindex: -1,
      // 造字列表
      CustomList: [],
      //获取图片地址
      baseurl: this.baseURL,
      //图片拖拽距离存储
      distanceX: 0,
      distanceY: 0,
      a: true,
      //子元素节点存储
      createNumber: [],
      createList: [],
      wordindex: "",
      //svg字体大小
      svgfont: 1,
      svgx: -100,
      svgy: 321,
      scalex: 1,
      scaley: 1,
      marx: -150,
      mary: -23,
      pathcolor: "white",
      matrixsx: 1,
      matrixsy: 1,
      choosecodeIndex: -1,
      /*  */
      seleteWords: null,
    };
  },
  computed: {
    DialogAddVisible: {
      get() {
        return this.wordVisible;
      },
      set(val) {
        console.log("走了set");
        this.$emit("closer", val);
      },
    },
  },
  mounted() {
    this.Makelist();
    this.strokelist();
  },

  methods: {
    showbtn(i) {
      this.$refs.showctn.style.display = `${i}`;
    },
    seleteWordClick() {
      if (!this.seleteWords) return this.$message.warning("请先选择字");
      this.DialogAddVisible = false;
      this.$emit("seleteWordClick", this.seleteWords);
    },
    //搜索造字分页数据
    searchkeyword() {
      this.Makelist(this.unfoldkeyword);
    },
    //获取造字分页数据
    Makelist(key) {
      this.$request({
        url: "/api/proofread/custom-word",
        method: "GET",
        headers: {
          accept: "text/plain",
        },
        params: {
          PageSize: this.PageSize,
          PageIndex: 1,
          SkipCount: "",
          MaxResultCount: "",
          Sorting: "",
          Keyword: key,
        },
      })
        .then((res) => {
          // console.log("res", res);
          this.CustomList = res.items;
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //加载字体列表
    loadunfold() {
      this.PageIndex++;
      this.$request({
        url: "/api/proofread/custom-word",
        method: "GET",
        headers: {
          accept: "text/plain",
        },
        params: {
          PageSize: this.PageSize,
          PageIndex: this.PageIndex,
          SkipCount: "",
          MaxResultCount: "",
          Sorting: "",
        },
      })
        .then((res) => {
          // console.log("res", res);
          this.CustomList.push(...res.items);
          // this.Makelist()
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    //边旁部首划数
    selectChanged(value) {
      console.log(value);
      this.strokecount = value;
      this.strokecount = value;
      this.strokecount = value;
      this.strokelist(value);
    },
    //根据笔画数获取偏旁
    strokelist(value) {
      this.$request({
        url: "/api/proofread/custom-word/by-count",
        method: "GET",
        headers: {
          accept: "text/plain",
        },
        params: {
          strokeCount: value,
        },
      })
        .then((res) => {
          // console.log("res", res);
          this.strokefont = res;
        })
        .catch((e) => {
          console.log("e", e);
        });
    },
    // 单击边旁部首
    btnstroke(i) {
      this.strokeindex = i;
    },
    //获取汉子编码
    choosecode(item, index) {
      this.$emit("changefont", item.code);
      this.choosecodeIndex = index;
      this.seleteWords = item;
    },
    //应用边旁部首
    application() {
      console.log(this.strokefont[this.strokeindex].partial);
      this.createNumber.push(this.strokefont[this.strokeindex].partial);
      // this.svgx = 0;
      // this.svgy = 321;
    },
    //创建子元素节点
    createbox() {
      this.createNumber.push(this.form.name);
      console.log(this.createNumber);
      this.form.name = "";
    },
    //删除偏旁部首
    detailword() {
      console.log("删除");
      console.log(this.createNumber.length);
      this.createNumber.splice(this.createNumber.length - 1, 1);
    },
    //保存组合字体-新增自造字
    savefont() {
      this.pathcolor = "white";
      setTimeout((p) => {
        let svgfont = document.getElementById("svgfont");
        console.log(svgfont.outerHTML);
        this.$request({
          url: "/api/proofread/custom-word",
          method: "POST",
          data: {
            svgXml: `<?xml version="1.0" encoding="UTF-8" standalone="no"?><svg xmlns="http://www.w3.org/2000/svg" >${svgfont.outerHTML}</svg>`,
            code: `[${this.createNumber.join("")}]`,
            width: 375,
            height: 375,
          },
        })
          .then((res) => {
            console.log(res);
          })
          .catch((e) => {
            //return this.$message.error("服务器异常~");
          });
      }, 500);
    },
    //拖拽边旁部首
    wordmove(e, i) {
      // console.log(e);
      let that = this;
      that.wordindex = i;
      that.pathcolor = "black";
      let startpos = {};
      startpos.x = e.clientX;
      startpos.y = e.clientY;

      console.log(i);

      // svg图片位置
      let svgfont = document.getElementById(i + "MakeWorda");
      let x = parseInt(svgfont.getAttribute("x"));
      let y = parseInt(svgfont.getAttribute("y"));
      //小圆点位置
      let dropbox = document.getElementById(i + "MakeWordb");
      // console.log(that.pathid, i);
      console.log(dropbox);

      console.log(dropbox.getAttribute("transform"));
      console.log(svgfont.getAttribute("transform"));

      let trans = dropbox.getAttribute("transform").split(",");
      let transx = parseInt(trans[4]);
      let transy = parseInt(trans[5].slice(0, trans[5].length - 1));
      // console.log(transx, transy);

      function move(e) {
        // console.log(e);
        if (that.a) {
          svgfont.setAttribute("x", x + e.clientX - startpos.x);
          svgfont.setAttribute("y", y + e.clientY - startpos.y);
          dropbox.setAttribute(
            "transform",
            `matrix(1,0,0,1,${transx + e.clientX - startpos.x},${
              transy + e.clientY - startpos.y
            })`
          );
        }
      }
      //鼠标按下移动时动态获取鼠标位置
      document.addEventListener("mousemove", move);
      //鼠标放下时，停止拖拽
      document.addEventListener("mouseup", function () {
        // console.log("鼠标抬起了");
        that.pathid = -1;
        document.removeEventListener("mousemove", move);
      });
    },
    // svg圆点拖拽缩放偏旁部首
    dropmove(e, i) {
      let that = this;
      that.a = false;
      console.log(e);
      let startpos = {};
      startpos.x = e.clientX;
      startpos.y = e.clientY;
      // 小圆点位置
      let dropbox = document.getElementById(i + "MakeWordb");
      // console.log(dropbox.getAttribute("transform").split(","));
      let trans = dropbox.getAttribute("transform").split(",");
      let transx = parseInt(trans[4]);
      let transy = parseInt(trans[5].slice(0, trans[5].length - 1));
      // console.log(transx, transy);
      // 图片位置
      let svgbox = document.getElementById(i + "MakeWorda");
      console.log(svgbox.getAttribute("transform").split(","));
      let matrixs = svgbox.getAttribute("transform").split(",");
      let matrixsx = parseFloat(matrixs[0].slice(7));
      let matrixsy = parseFloat(matrixs[3]);
      console.log(matrixsx, matrixsy);

      function move(e) {
        let x = e.clientX - startpos.x;
        let y = e.clientY - startpos.y;
        console.log(matrixsx + x * 0.004, matrixsy + y * 0.004);
        dropbox.setAttribute(
          "transform",
          `matrix(1,0,0,1,${transx + x},${transy + y})`
        );
        svgbox.setAttribute(
          "transform",
          `matrix(${matrixsx + x * 0.004},0,0,${matrixsy + y * 0.004},0,0)`
        );
      }
      //鼠标按下移动时动态获取鼠标位置
      document.addEventListener("mousemove", move);
      //鼠标放下时，停止拖拽
      document.addEventListener("mouseup", function () {
        // console.log("鼠标抬起了");
        that.a = true;
        document.removeEventListener("mousemove", move);
      });
    },
    // 缩放偏旁部首
    dropdown(e, i) {
      let that = this;
      that.a = false;
      // console.log(e);
      let startpos = {};
      startpos.x = e.clientX;
      startpos.y = e.clientY;
      let wordbox = document.getElementById(i);
      let dropwidth = wordbox.style.width.substring(
        0,
        wordbox.style.width.length - 2
      );
      let dropheight = wordbox.style.height.substring(
        0,
        wordbox.style.height.length - 2
      );
      console.log(wordbox.style.width);
      function move(e) {
        // console.log((e.clientX - startpos.x) * 0.001);

        wordbox.style.width =
          parseInt(dropwidth) + e.clientX - startpos.x + "px";
        wordbox.style.height =
          parseInt(dropheight) + e.clientY - startpos.y + "px";
      }
      //鼠标按下移动时动态获取鼠标位置
      document.addEventListener("mousemove", move);
      //鼠标放下时，停止拖拽
      document.addEventListener("mouseup", function () {
        // console.log("鼠标抬起了");
        that.a = true;
        document.removeEventListener("mousemove", move);
      });
    },
  },
};
</script>

<style lang="less" scoped>
// svg {

//     width: 100%;

// }

.inputbox {
  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}
.wordctn {
  width: 100%;
  height: 400px;
  margin-top: 10px;
  justify-content: space-between;
  display: flex;
  position: relative;
}
.wordctn-left {
  position: relative;
  width: 50%;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
}
.wordctn-right {
  width: 47%;
  height: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  position: relative;
}
.wordctn-right-title {
  width: 100%;
  height: 60px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
}
.wordctn-right-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: right;
}
.showbtn {
  top: 50%;
  left: 0;
  position: absolute;
  width: 15px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(189, 185, 185);
  display: flex;
  align-items: center;
}
.showbtn:hover {
  background: #ddd;
}
.showbtn1 {
  top: 44.5%;
  right: 18px;
  position: absolute;
  width: 15px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgb(189, 185, 185);
  display: flex;
  align-items: center;
}
.showbtn1:hover {
  background: #ddd;
}
.showctn {
  // padding: 20px 20px;
  position: absolute;
  width: 100%;
  height: 90%;
  left: 0;
  bottom: 0;
  background: white;
  transition: 0.5s;
  display: none;
}
.unfold-title {
  width: 100%;
}
.ucodebox {
  padding-left: 10px;
  width: 300px;
  height: 30px;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: flex;
  align-items: center;
  background: #ddd;
}
.unfold-list {
  padding: 10px;
  width: 100%;
  height: 420px;
  overflow-x: hidden;
  overflow-y: scroll;
}
.unfoldListBox1 {
  margin-left: 10px;
  margin-top: 10px;
  float: left;
  width: 110px;
  height: 110px;
  border: 1px solid #ddd;
  background: #ddd;
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 80%;
  position: relative;
}
.unfoldListBox {
  margin-left: 10px;
  margin-top: 10px;
  float: left;
  width: 110px;
  height: 110px;
  border: 1px solid #ddd;
  background-repeat: no-repeat;
  background-position: 10px 10px;
  background-size: 80%;
  position: relative;
}
.unfold-list-bottom {
  width: 100%;
  height: 21px;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.unfold-bottom {
  width: 100%;
  height: 70px;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 20px;
  position: absolute;
  bottom: 0;
}
.stroke-list {
  width: 100%;
  height: 70%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.strockbox {
  font-size: 24px;
  margin-top: 5px;
  float: left;
  width: 44px;
  height: 45px;
  border: 1px solid #ccc;
  border-radius: 6px;
  color: #000;
  font-family: 宋体;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.strockbox:hover {
  background: #ddd;
}
.wordctn-left-box {
  position: absolute;
  left: 0;
  top: 0;
  // width: 100%;
  // height: 100%;
  // background: rosybrown;
  color: #000;
  cursor: pointer;
  font-size: 375px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 宋体;
}
.drop {
  position: absolute;
  right: -5px;
  bottom: -5px;
  border-radius: 100%;
  border: 1px solid black;
  width: 15px;
  height: 15px;
}
</style>